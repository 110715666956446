import * as types from '../constants/loginConstants';

export const login = payload => ({
  type: types.LOGIN,
  payload,
});

export const logout = payload => ({
  type: types.LOGOUT,
  payload,
});

const styles = {
  chartFluid: {
    width: '95%',
    minWidth: 100,
    height: '95%',
    minHeight: 150
  }
};

export default styles;

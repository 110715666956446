/* eslint-disable linebreak-style */
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import get from 'lodash/get';

const AuthRoute = ({ component: Component, ...rest }) => {
  const { location } = window;
  const uri = location.href;

  const sStorage = JSON.parse(sessionStorage.getItem('sStorage'));
  if (uri.includes('token')) {
    const url = new URL(uri);
    const searchParams = new URLSearchParams(url.search);
    const tTime = new Date();
    tTime.setMinutes(tTime.getMinutes() + searchParams.get('tokenTime'));
    const payload = {
      ...sStorage,
      token: searchParams.get('token'),
      refreshToken: searchParams.get('refreshToken'),
      tokenTime: tTime.toISOString()
    };
    sessionStorage.setItem('sStorage', JSON.stringify(payload));
  }

  return (
    <Route
      {...rest}
      render={props => (
        get(sStorage, 'token', null) && get(sStorage, 'refreshToken', null) ? <Component {...props} />
          : <Redirect to={{ pathname: '/login' }} />
      )}
    />
  );
};

AuthRoute.propTypes = {
  component: PropTypes.func.isRequired
};

export default AuthRoute;

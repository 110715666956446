/* eslint-disable linebreak-style */
import axios from 'axios';
import get from 'lodash/get';


function setParams(config) {
  const headers = get(config, 'headers', {});
  const sStorage = JSON.parse(sessionStorage.getItem('sStorage'));
  headers.common.Authorization = `Bearer ${get(sStorage, 'token', '')}`;
  const result = { ...config, headers };

  return result;
}

const API2 = axios.create({
  baseURL: 'https://iboard-api.ntgistudio.com/cad/api',
  // baseURL: 'https://localhost:44322/api',
});

API2.interceptors.request.use(
  (config) => setParams(config),
  (error) => Promise.reject(error)
);

export default API2;

/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 *   IMPORTANT: This file is used by the internal build
 *   script `extract-intl`, and must use CommonJS module syntax
 *   You CANNOT use import/export in this file.
 */
const addLocaleData = require('react-intl').addLocaleData; //eslint-disable-line
const enLocaleData = require('react-intl/locale-data/en');
const ptLocaleData = require('react-intl/locale-data/pt');

const enTranslationMessages = require('./translations/en.json');
const ptTranslationMessages = require('./translations/pt.json');

addLocaleData(enLocaleData);
addLocaleData(ptLocaleData);

const sStorage = sessionStorage.getItem('sStorage');
let temp = 'pt';
if (sStorage && sStorage !== null && JSON.parse(sStorage).lang && JSON.parse(sStorage).lang !== '') {
  temp = JSON.parse(sStorage).lang;
} else {
  sessionStorage.setItem('sStorage', JSON.stringify({ lang: temp }));
}

const DEFAULT_LOCALE = temp;

const VISIT_STASUS = {};

// prettier-ignore
const appLocales = [
  'pt',
  'en'
];

const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages = locale !== DEFAULT_LOCALE
    ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages)
    : {};
  const flattenFormattedMessages = (formattedMessages, key) => {
    const formattedMessage = !messages[key] && locale !== DEFAULT_LOCALE
      ? defaultFormattedMessages[key]
      : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  };
  return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

const translationMessages = {
  en: formatTranslationMessages('en', enTranslationMessages),
  pt: formatTranslationMessages('pt', ptTranslationMessages),
};

const handlePageList = (roleName) => {
  let validPageList = [];
  // home, general, town, townStatus, townStatistics, townMap, collaborators, quality, prod, invoices, users, companies, statistics
  // ESPECTADOR, ANALISTA, CADASTRADOR, SUPERVISOR, COORDENADOR, ADMINISTRADOR
  if(roleName === 'ESPECTADOR') {
    return validPageList;
  }
  validPageList.push('home');
  validPageList.push('prod');
  if(roleName === 'ANALISTA') {
    validPageList.push('analyst');
    return validPageList;
  }
  if(roleName === 'CADASTRADOR') {
    validPageList.push('signer');
    return validPageList;
  }
  validPageList.push('signer');
  validPageList.push('analyst');
  validPageList.push('general');
  validPageList.push('town');
  validPageList.push('townStatus');
  validPageList.push('townStatistics');
  validPageList.push('townMap');
  validPageList.push('collaborators');
  //validPageList.push('statistics');
  if(roleName === 'SUPERVISOR' || roleName === 'COORDENADOR') {
    return validPageList;
  }
  validPageList.push('invoices');
  if(roleName === 'ADMINISTRADOR') {
    //validPageList.push('manage');
    //validPageList.push('users');
    //validPageList.push('companies');
    return validPageList;
  }
}

exports.appLocales = appLocales;
exports.formatTranslationMessages = formatTranslationMessages;
exports.translationMessages = translationMessages;
exports.getPageList = handlePageList;
exports.DEFAULT_LOCALE = DEFAULT_LOCALE;

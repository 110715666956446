/*
 *
 * LanguageProvider
 *
 * this component connects the redux state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { IntlProvider } from 'react-intl';
import get from 'lodash/get';

import { makeSelectLocale } from './selectors';
import { CHANGE_LOCALE } from './constants';

export const LangContext = React.createContext();

export class LanguageProvider extends React.Component {
  state = {
    locale: get(JSON.parse(sessionStorage.getItem('sStorage')), 'lang', 'pt')
  }

  handlerChange = (value) => {
    const { changelocal } = this.props;
    const { locale }= this.state;
    if (value !== locale) {
      const sStorage = JSON.parse(sessionStorage.getItem('sStorage'));
      sessionStorage.setItem('sStorage', JSON.stringify({
        ...sStorage,
        lang: value,
      }));
      // window.location.reload();
      changelocal(value);
      this.setState({ locale: value });
    }
  }
  // eslint-disable-line react/prefer-stateless-function
  render() {
    const { messages, children } = this.props;
    const { locale } = this.state;
    return (
      <IntlProvider
        locale={locale}
        key={locale}
        messages={messages[locale]}
      >
        <LangContext.Provider value={this.handlerChange}>
          {React.Children.only(children)}
        </LangContext.Provider>
      </IntlProvider>
    );
  }
}

LanguageProvider.propTypes = {
  locale: PropTypes.string.isRequired,
  messages: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
};

const mapStateToProps = createSelector(makeSelectLocale(), locale => ({
  locale,
}));

const mapDispatchToProps = (dispatch) => ({
  changelocal: (lang) => dispatch({ type: CHANGE_LOCALE, locale: lang })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LanguageProvider);

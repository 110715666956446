import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { compose } from 'redux';
import logoSmall from 'dan-images/logoSmallX.svg';
import drawing from 'dan-images/gistudio_v.svg';
import fsa_g from 'dan-images/fsa_g.svg';

import MainMenu from './MainMenu';
import styles from './sidebar-jss';

class SidebarContent extends React.Component {
  state = {
    transform: 0,
  };

  componentDidMount = () => {
    // Scroll content to top
    const mainContent = document.getElementById('sidebar');
    mainContent.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    const mainContent = document.getElementById('sidebar');
    mainContent.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = (event) => {
    const scroll = event.target.scrollTop;
    this.setState({
      transform: scroll
    });
  }

  render() {
    const {
      classes,
      drawerPaper,
      toggleDrawerOpen,
      loadTransition,
      leftSidebar,
      dataMenu,
      isLogin,
    } = this.props;
    const { transform } = this.state;

    return (
      <div className={classNames(classes.drawerInner, !drawerPaper ? classes.drawerPaperClose : '')}>
        <div className={classes.drawerHeader}>
          <div
            className={classNames(drawerPaper ? classes.largeLogo : classes.profile, classes.user)}
            style={{
              opacity: 1 - transform / 100,
              marginTop: transform * -0.3,
              display: 'flex',
              justifyContent: 'space-around'
            }}
          >
            <img
              src={drawerPaper ? drawing : logoSmall}
              alt="GSTUDIOpi"
              className={drawerPaper ? classes.logo : classes.logoSmall}
            />
          </div>
        </div>
        
        <div
          id="sidebar"
          className={
            classNames(
              classes.menuContainer,
              leftSidebar && classes.rounded,
              isLogin && classes.withProfile
            )
          }
        >
          <div style={{backgroundColor:'#5c8a8a', color: 'white', fontSize:'18px', borderRadius: '0 15px 15px 0', maxHeight:'30px', paddingLeft:'10px'}}>
            Dados por Contrato
          </div>
          <MainMenu loadTransition={loadTransition} dataMenu={dataMenu.filter(item=>item.type === 1)} toggleDrawerOpen={toggleDrawerOpen} />
          
          <div style={{backgroundColor:'#5c8a8a', color: 'white', fontSize:'18px', borderRadius: '0 15px 15px 0', maxHeight:'30px', paddingLeft:'10px'}}>
            Dados por Empresa
          </div>
          <MainMenu loadTransition={loadTransition} dataMenu={dataMenu.filter(item=>item.type === 2)} toggleDrawerOpen={toggleDrawerOpen} />
          
          <div className={classes.support}> 
            <div
              className={classes.by}
            >
              <div style={{ marginBottom: 5 }}>Powered by</div>
              <img
                src={fsa_g}
                alt="FSA"
              />
            </div>
          </div>

        </div>
      </div>
    );
  }
}

SidebarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  drawerPaper: PropTypes.bool.isRequired,
  toggleDrawerOpen: PropTypes.func,
  loadTransition: PropTypes.func,
  leftSidebar: PropTypes.bool.isRequired,
  dataMenu: PropTypes.array.isRequired,
  isLogin: PropTypes.bool,
};

SidebarContent.defaultProps = {
  turnDarker: false,
  toggleDrawerOpen: () => {},
  loadTransition: () => {},
  anchorEl: null,
  isLogin: true,
  login: undefined,
};

const mapStateToProps = (state) => ({
  login: JSON.parse(JSON.stringify(state.get('login')))
});

export default compose(withStyles(styles), connect(mapStateToProps))(SidebarContent);

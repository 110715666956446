import { fromJS } from 'immutable';
import get from 'lodash/get';
import { INIT } from '../constants/reduxFormConstants';
import { LOGIN, LOGOUT } from "../constants/contractConstants";
const sStorage = JSON.parse(sessionStorage.getItem('sStorage'));

const initialState = {
  contractIndex: get(sStorage, 'contract.contractIndex', 0),
  currentPageList: get(sStorage, 'contract.currentPageList', [])
};
const initialImmutableState = fromJS(initialState);

export default function reducer(state = initialImmutableState, action = {}) {
  switch (action.type) {
    case INIT:
      return state;
    case LOGIN:
      return state.merge(action.payload);
    case LOGOUT:
      sessionStorage.removeItem('sStorage');
      return fromJS({});
    default:
      return state;
  }
}

/* eslint-disable linebreak-style */
import React from 'react';
import PropTypes from 'prop-types';
import styles from 'dan-components/Forms/user-jss';
import get from 'lodash/get';
import { withSnackbar } from 'notistack';
import { injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { login } from 'dan-redux/actions/reduxLoginActions';
import { initContract } from 'dan-redux/actions/reduxContractActions';
/* core */
import { withStyles } from '@material-ui/core/styles';
/* our */
import LoginForm from './LoginForm';
import AxiosAPI from 'dan-api/axiosAPI';
import { getPageList } from '../../../i18n';

class Login extends React.Component {
  state = {
    userData: {},
    loading: false
  }

  _isMounted = false;

  componentDidMount = () => {
    this._isMounted = true;
    const { history } = this.props;
    const sStorage = JSON.parse(sessionStorage.getItem('sStorage'));
    if (get(sStorage, 'token', null) && get(sStorage, 'refreshToken', null)) {
      history.push('/');
    }
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  }

  submitForm = (values) => {
    this.setState({ loading: true });

    this.APILoginV2(values);
  }

  APILoginV2 = async (values) => {
    const { enqueueSnackbar, intl } = this.props;

    const user = await AxiosAPI.getAuth(values.username, values.password);
    if (this._isMounted === true) {
      if (user.code === '200') {
        await this.setState({ userData: user.response });
        if (this._isMounted === true) {
          this.proccessData();
        }
      } else {
        enqueueSnackbar(intl.formatMessage({ id: 'Invalid username or password' }), { variant: 'error' });
        this.setState({ loading: false });
      }
    }
  }

  handleNotifications = async (id, role) => {
    const { login: loginAction } = this.props;
    let count = 0;
    /*
    if (role === ROLE.RESPONSIBLE) {
      const result = await AxiosAPI.getCountVisitRequestsByResponsibleAndStatus(id, VISIT_STASUS.INPROCESS, loginAction);
      if (result !== null) {
        if (result.code === '200') {
          count = result.response;
        }
      }
    } else if (role === ROLE.VISITOR) {
      const result = await AxiosAPI.getCountVisitRequestsByVisitorAndStatus(id, VISIT_STASUS.ACCEPTED, loginAction);
      if (result !== null) {
        if (result.code === '200') {
          count = result.response;
        }
      }
    }
    */
    return count;
  }

  proccessData = async () => {
    const { userData } = this.state;
    const {
      login: loginAction,
      history,
      initContract
    } = this.props;

    if (get(userData, 'token', null) && get(userData, 'refreshToken', null)) {
      const sStorage = JSON.parse(sessionStorage.getItem('sStorage'));
      const tokenTime = new Date();
      tokenTime.setMinutes(tokenTime.getMinutes() + get(userData, 'tokenTime', 0));
      const contractList = get(userData, 'contractList', []);
      const payload = {
        ...sStorage,
        id: get(userData, 'id', undefined),
        username: get(userData, 'username', ''),
        name: get(userData, 'name', ''),
        token: get(userData, 'token', ''),
        refreshToken: get(userData, 'refreshToken', ''),
        tokenTime,
        contractList,

        phone: get(userData, 'phone', ''),
        email: get(userData, 'email', ''),
        photo: get(userData, 'photo', null)
      };

      const notificationCount = await this.handleNotifications(payload.id, payload.roleId);
      if (this._isMounted === true) {
        const currentPageList = getPageList(contractList[0].roleName);
        loginAction({ ...payload, notificationCount });
        initContract({ contractIndex: 0, currentPageList });
        sessionStorage.setItem('sStorage', JSON.stringify({ ...payload, notificationCount, contract: { contractIndex: 0, currentPageList } }));
        if(contractList[0].roleName === 'ESPECTADOR')
        {
          history.push('/app/view');
          return;
        }
        history.push('/app');
      }
    }
  }

  render() {
    const { classes } = this.props;
    const { loading } = this.state;
    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.userFormWrap}>
            <LoginForm
              handleSubmit={(values) => () => this.submitForm(values)}
              loading={loading}
            />
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  intl: PropTypes.shape({}).isRequired,
  login: PropTypes.func.isRequired,
  initContract: PropTypes.func.isRequired,
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
};

const constDispatchToProps = dispatch => ({
  login: bindActionCreators(login, dispatch),
  initContract: bindActionCreators(initContract, dispatch),
});

const LoginMapped = connect(
  () => ({}),
  constDispatchToProps
)(Login);

export default withSnackbar(withStyles(styles)(injectIntl(LoginMapped)));

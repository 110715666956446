import Loadable from 'react-loadable';
import Loading from 'dan-components/Loading';

export const Login = Loadable({
  loader: () => import('./Pages/Users/Login'),
  loading: Loading,
});
export const NotFound = Loadable({
  loader: () => import('./NotFound/NotFound'),
  loading: Loading,
});
export const Error = Loadable({
  loader: () => import('./Pages/Error'),
  loading: Loading,
});
export const Maintenance = Loadable({
  loader: () => import('./Pages/Maintenance'),
  loading: Loading,
});

/* Our */
export const Profile = Loadable({
  loader: () => import('./Pages/GSTUDIOpi/CommonViews/Profile/Profile'),
  loading: Loading,
});
export const Home = Loadable({
  loader: () => import('./Pages/GSTUDIOpi/CommonViews/Home/Home'),
  loading: Loading,
});
export const General = Loadable({
  loader: () => import('./Pages/GSTUDIOpi/CommonViews/Info/General'),
  loading: Loading,
});
export const Production = Loadable({
  loader: () => import('./Pages/GSTUDIOpi/CommonViews/Info/Production'),
  loading: Loading,
});
export const Town = Loadable({
  loader: () => import('./Pages/GSTUDIOpi/CommonViews/Info/Town'),
  loading: Loading,
});
export const TownStatistics = Loadable({
  loader: () => import('./Pages/GSTUDIOpi/CommonViews/Info/TownStatistics'),
  loading: Loading,
});
export const TownMap = Loadable({
  loader: () => import('./Pages/GSTUDIOpi/CommonViews/Info/Map'),
  loading: Loading,
});
export const Collaborators = Loadable({
  loader: () => import('./Pages/GSTUDIOpi/CommonViews/Info/Collaborators'),
  loading: Loading,
});
export const Quality = Loadable({
  loader: () => import('./Pages/GSTUDIOpi/CommonViews/Info/Quality'),
  loading: Loading,
});
export const Statistics = Loadable({
  loader: () => import('./Pages/GSTUDIOpi/CommonViews/Info/Statistics'),
  loading: Loading,
});
export const UsersVerification = Loadable({
  loader: () => import('./Pages/GSTUDIOpi/Administrator/Users/UsersVerification'),
  loading: Loading,
});
export const UserRegistration = Loadable({
  loader: () => import('./Pages/GSTUDIOpi/Administrator/Users/UserRegistration'),
  loading: Loading,
});
export const CompaniesVerification = Loadable({
  loader: () => import('./Pages/GSTUDIOpi/Administrator/Companies/CompaniesVerification'),
  loading: Loading,
});
export const CompanyRegistration = Loadable({
  loader: () => import('./Pages/GSTUDIOpi/Administrator/Companies/CompanyRegistration'),
  loading: Loading,
});
export const CompanyModification = Loadable({
  loader: () => import('./Pages/GSTUDIOpi/Administrator/Companies/CompanyModification'),
  loading: Loading,
});
export const Invoices = Loadable({
  loader: () => import('./Pages/GSTUDIOpi/Administrator/Finance/Invoices'),
  loading: Loading,
});

/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import '@babel/polyfill';

import '../node_modules/@syncfusion/ej2-react-grids/styles/material.css';

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { SnackbarProvider } from 'notistack';
import history from 'utils/history';

import 'sanitize.css/sanitize.css';
import './app.css';
// Import root app
import App from 'containers/App';
import './styles/layout/base.scss';

// Import Providers
import LanguageProvider from 'containers/LanguageProvider';

// Load the favicon and the .htaccess file
// Load the favicon and the .htaccess file
/* eslint-disable import/no-unresolved, import/extensions */
import '!file-loader?name=[name].[ext]!../public/favicons/favicon.ico'; // eslint-disable-line
import 'file-loader?name=.htaccess!./.htaccess'; // eslint-disable-line
/* eslint-enable import/no-unresolved, import/extensions */

import configureStore from './redux/configureStore';

// Import i18n messages
import { translationMessages } from './i18n';
import { L10n } from '@syncfusion/ej2-base';



L10n.load({
  'pt': { 
    "tab": {
    "closeButtonTitle": "Fechar"
    },
    "pivotview": {
        "grandTotal": "Total geral",
        "total": "Total",
        "value": "Valor",
        "noValue": "Sem valor",
        "row": "Linha",
        "column": "Coluna",
        "collapse": "Colapso",
        "expand": "Expandir",
        "rowAxisPrompt": "Soltar linha aqui",
        "columnAxisPrompt": "Solte a coluna aqui",
        "valueAxisPrompt": "Solte o valor aqui",
        "filterAxisPrompt": "Largue o filtro aqui",
        "filter": "Filtro",
        "filtered": "Filtrado",
        "sort": "Ordenar",
        "filters": "Filtros",
        "rows": "Linhas",
        "columns": "Colunas",
        "values": "Valores",
        "close": "Fechar",
        "cancel": "Cancelar",
        "delete": "Excluir",
        "CalculatedField": "Campo calculado",
        "createCalculatedField": "Criar campo calculado",
        "fieldName": "Digite o nome do campo",
        "error": "Erro",
        "invalidFormula": "Fórmula inválida.",
        "dropText": "Exemplo: ('Sum (Order_Count)' + 'Sum (In_Stock)') * 250",
        "dropTextMobile": "Adicione campos e edite a fórmula aqui.",
        "dropAction": "O campo calculado não pode ser colocado em nenhuma outra região, exceto no eixo do valor.",
        "alert": "Alerta",
        "warning": "Atenção",
        "ok": "Está bem",
        "search": "Procurar",
        "drag": "Arrasto",
        "remove": "Retirar",
        "allFields": "Todos os campos",
        "formula": "Fórmula",
        "addToRow": "Adicionar à linha",
        "addToColumn": "Adicionar à coluna",
        "addToValue": "Adicionar ao valor",
        "addToFilter": "Adicionar ao filtro",
        "emptyData": "Não há registros a serem exibidos",
        "fieldExist": "Um campo já existe neste nome. Digite um nome diferente.",
        "confirmText": "Um campo de cálculo já existe neste nome. Quer substituir?",
        "noMatches": "Sem combinações",
        "format": "Resume os valores por",
        "edit": "Editar",
        "clear": "Limpar",
        "formulaField": "Arraste e solte campos para a fórmula",
        "dragField": "Arraste o campo para a fórmula",
        "clearFilter": "Limpar",
        "by": "por",
        "all": "Todos",
        "multipleItems": "Vários itens",
        "member": "Membro",
        "label": "Rótulo",
        "date": "Encontro",
        "enterValue": "Digite o valor",
        "chooseDate": "Digite a data",
        "Before": "Antes",
        "BeforeOrEqualTo": "Antes ou igual a",
        "After": "Depois de",
        "AfterOrEqualTo": "Depois ou igual a",
        "labelTextContent": "Mostrar os itens para os quais o rótulo",
        "dateTextContent": "Mostrar os itens para os quais a data",
        "valueTextContent": "Mostrar os itens para os quais",
        "Equals": "É igual a",
        "DoesNotEquals": "Não é igual",
        "BeginWith": "Começa com",
        "DoesNotBeginWith": "Não começa com",
        "EndsWith": "Termina com",
        "DoesNotEndsWith": "Não termina com",
        "Contains": "Contém",
        "DoesNotContains": "Não contém",
        "GreaterThan": "Maior que",
        "GreaterThanOrEqualTo": "Melhor que ou igual a",
        "LessThan": "Menor que",
        "LessThanOrEqualTo": "Menos que ou igual a",
        "Between": "Entre",
        "NotBetween": "Não entre",
        "And": "e",
        "Sum": "Soma",
        "Count": "Contagem",
        "DistinctCount": "Contagem Distinta",
        "Product": "produtos",
        "Avg": "Média",
        "Min": "Mín.",
        "SampleVar": "Amostra Var",
        "PopulationVar": "População Var",
        "RunningTotals": "Totais em Execução",
        "Max": "Máx.",
        "Index": "Índice",
        "SampleStDev": "Amostra StDev",
        "PopulationStDev": "População StDev",
        "PercentageOfRowTotal": "% do total da linha",
        "PercentageOfParentTotal": "% do total pai",
        "PercentageOfParentColumnTotal": "% do total da coluna pai",
        "PercentageOfParentRowTotal": "% do total da linha pai",
        "DifferenceFrom": "Diferença de",
        "PercentageOfDifferenceFrom": "% de diferença de",
        "PercentageOfGrandTotal": "% do total geral",
        "PercentageOfColumnTotal": "% do total da coluna",
        "NotEquals": "Diferente",
        "AllValues": "Todos os valores",
        "conditionalFormating": "Formatação condicional",
        "apply": "APLIQUE",
        "condition": "Adicionar condição",
        "formatLabel": "Formato",
        "valueFieldSettings": "Configurações do campo Valor",
        "baseField": "Campo base:",
        "baseItem": "Item base:",
        "summarizeValuesBy": "Resuma valores por:",
        "sourceName": "Nome do campo:",
        "sourceCaption": "Legenda do campo:",
        "example": "por exemplo:",
        "editorDataLimitMsg": " mais itens. Procure refinar ainda mais.",
        "details": "Detalhes",
        "manageRecords": "Gerenciar registros",
        "Years": "Anos",
        "Quarters": "Quartos",
        "Months": "Meses",
        "Days": "Dias",
        "Hours": "Horas",
        "Minutes": "Minutos",
        "Seconds": "Segundos",
        "save": "Salvar um relatório",
        "new": "Crie um novo relatório",
        "load": "Carga",
        "saveAs": "Salvar como relatório atual",
        "rename": "Renomear um relatório atual",
        "deleteReport": "Excluir um relatório atual",
        "export": "Exportação",
        "subTotals": "Subtotais",
        "grandTotals": "Totais gerais",
        "reportName": "Nome do relatório:",
        "pdf": "PDF",
        "excel": "Excel",
        "csv": "CSV",
        "png": "PNG",
        "jpeg": "JPEG",
        "svg": "SVG",
        "mdxQuery": "Consulta MDX",
        "showSubTotals": "Mostrar subtotais",
        "doNotShowSubTotals": "Não mostrar subtotais",
        "showSubTotalsRowsOnly": "Mostrar apenas subtotais de linhas",
        "showSubTotalsColumnsOnly": "Mostrar apenas subtotais de colunas",
        "showGrandTotals": "Mostrar totais gerais",
        "doNotShowGrandTotals": "Não mostrar totais gerais",
        "showGrandTotalsRowsOnly": "Mostrar apenas linhas de totais gerais",
        "showGrandTotalsColumnsOnly": "Mostrar apenas colunas do total geral",
        "fieldList": "Mostrar lista de campos",
        "grid": "Mostrar tabela",
        "toolbarFormatting": "Formatação condicional",
        "chart": "Gráfico",
        "reportMsg": "Digite o nome do relatório vaild !!!",
        "reportList": "Lista de relatórios",
        "removeConfirm": "Tem certeza de que deseja excluir este relatório?",
        "emptyReport": "Nenhum relatório encontrado !!",
        "bar": "Barra",
        "line": "Linha",
        "area": "Área",
        "scatter": "Dispersão",
        "polar": "Polar",
        "of": "do",
        "emptyFormat": "Nenhum formato encontrado !!!",
        "emptyInput": "Digite um valor",
        "newReportConfirm": "Deseja salvar as alterações no relatório?",
        "emptyReportName": "Digite um nome para o relatório",
        "qtr": "Qtr",
        "null": "nulo",
        "undefined": "Indefinido",
        "groupOutOfRange": "Fora de alcance",
        "fieldDropErrorAction": "O campo que você está movendo não pode ser colocado nessa área do relatório",
        "MoreOption": "Mais...",
        "aggregate": "Agregar",
        "drillThrough": "Perfurar",
        "ascending": "Crescente",
        "descending": "descendente",
        "number": "Número",
        "currency": "Moeda",
        "percentage": "Percentagem",
        "formatType": "Tipo de formato",
        "customText": "Símbolo Monetário",
        "symbolPosition": "Posição do símbolo",
        "left": "Esquerda",
        "right": "Certo",
        "grouping": "Agrupamento",
        "true": "Verdade",
        "false": "Falso",
        "decimalPlaces": "Casas decimais",
        "numberFormat": "Formatação de números",
        "memberType": "Tipo de campo",
        "formatString": "Formatar sequência",
        "expressionField": "Expressão",
        "customFormat": "Digite a string de formato personalizado",
        "selectedHierarchy": "Hierarquia pai",
        "olapDropText": "Exemplo: [Medidas]. [Quantidade do pedido] + ([Medidas]. [Quantidade do pedido] * 0,10)",
        "Percent": "Por cento",
        "Custom": "personalizadas",
        "Measure": "A medida",
        "Dimension": "Dimensão",
        "Standard": "Padrão",
        "blank": "(Em branco)",
        "fieldTooltip": "Arraste e solte campos para criar uma expressão. E, se você deseja editar os campos calculados existentes! Para isso, basta selecionar o campo em 'Membros calculados'.",
        "QuarterYear": "Trimestre do ano",
        "fieldTitle": "Nome do campo",
        "drillError": "Não é possível mostrar os itens brutos dos campos calculados.",
        "caption": "Legenda do campo",
        "copy": "cópia de",
        "defaultReport": "Relatório padrão",
        "customFormatString": "Formato personalizado",
        "invalidFormat": "Formato Inválido.",
        "group": "Grupo",
        "unGroup": "Desagrupar",
        "invalidSelection": "Não é possível agrupar essa seleção.",
        "groupName": "Digite a legenda para exibir no cabeçalho",
        "captionName": "Digite a legenda para o campo de grupo",
        "selectedItems": "Itens selecionados",
        "groupFieldCaption": "Legenda do campo",
        "groupTitle": "Nome do grupo",
        "startAt": "Começando às",
        "endAt": "Terminando às",
        "groupBy": "Intervalo por",
        "selectGroup": "Selecionar grupos",
        "numberFormatString": "Exemplo: C, P, 0000%, ### 0. ## 0 #, etc.",
        "stackingcolumn": "Coluna empilhada",
        "stackingbar": "Barra empilhada",
        "stackingarea": "Área empilhada",
        "stepline": "Linha de passo",
        "steparea": "Área da etapa",
        "splinearea": "Área do spline",
        "spline": "Spline",
        "stackingcolumn100": "Coluna empilhada 100%",
        "stackingbar100": "Barra 100% empilhada",
        "stackingarea100": "Área 100% empilhada",
        "bubble": "bolha",
        "pareto": "Pareto",
        "radar": "Radar",
        "chartTypeSettings": "Configurações de tipo de gráfico",
        "multipleAxes": "Múltiplos eixos",
        "sortAscending": "Classificar ordem crescente",
        "sortDescending": "Classificar ordem decrescente",
        "sortNone": "Classificar ordem dos dados",
        "clearCalculatedField": "Limpar informações do campo editado",
        "editCalculatedField": "Editar campo calculado",
        "ChartType": "Tipo de Gráfico",
        "yes": "sim",
        "no": "Não",
        "numberFormatMenu": "Formatação de números ...",
        "conditionalFormatingMenu": "Formatação condicional...",
        "removeCalculatedField": "Tem certeza de que deseja excluir este campo calculado?",
        "replaceConfirmBefore": "Um relatório chamado",
        "replaceConfirmAfter": " já existe. Quer substituir?",
        "pie": "Torta",
        "funnel": "Funil",
        "doughnut": "Rosquinha",
        "pyramid": "Pirâmide",
        "showLegend": "Mostrar legenda",
        "exit": "Saída",
        "invalidJSON": "Dados JSON inválidos",
        "invalidCSV": "Dados CSV inválidos",
        "stacked": "Empilhado",
        "single": "solteiro",
        "multipleAxisMode": "Modo de eixo múltiplo"
    },
    "pivotfieldlist": {
        "staticFieldList": "Lista de campos dinâmicos",
        "fieldList": "Lista de campos",
        "dropFilterPrompt": "Largue o filtro aqui",
        "dropColPrompt": "Solte a coluna aqui",
        "dropRowPrompt": "Soltar linha aqui",
        "dropValPrompt": "Solte o valor aqui",
        "addPrompt": "Adicionar campo aqui",
        "adaptiveFieldHeader": "Escolha o campo",
        "centerHeader": "Arraste os campos entre os eixos abaixo:",
        "add": "adicionar",
        "drag": "Arrasto",
        "filter": "Filtro",
        "filtered": "Filtrado",
        "sort": "Ordenar",
        "remove": "Retirar",
        "filters": "Filtros",
        "rows": "Linhas",
        "columns": "Colunas",
        "values": "Valores",
        "CalculatedField": "Campo calculado",
        "createCalculatedField": "Criar campo calculado",
        "fieldName": "Digite o nome do campo",
        "error": "Erro",
        "invalidFormula": "Fórmula inválida.",
        "dropText": "Exemplo: ('Sum (Order_Count)' + 'Sum (In_Stock)' ') * 250",
        "dropTextMobile": "Adicione campos e edite a fórmula aqui.",
        "dropAction": "O campo calculado não pode ser colocado em nenhuma outra região, exceto no eixo do valor.",
        "search": "Procurar",
        "close": "Fechar",
        "cancel": "Cancelar",
        "delete": "Excluir",
        "alert": "Alerta",
        "warning": "Atenção",
        "ok": "Está bem",
        "allFields": "Todos os campos",
        "formula": "Fórmula",
        "fieldExist": "Um campo já existe neste nome. Digite um nome diferente.",
        "confirmText": "Um campo de cálculo já existe neste nome. Quer substituir?",
        "noMatches": "Sem combinações",
        "format": "Resume os valores por",
        "edit": "Editar",
        "clear": "Limpar",
        "formulaField": "Arraste e solte campos para a fórmula",
        "dragField": "Arraste o campo para a fórmula",
        "clearFilter": "Limpar",
        "by": "por",
        "enterValue": "Digite o valor",
        "chooseDate": "Digite a data",
        "all": "Todos",
        "multipleItems": "Vários itens",
        "Equals": "É igual a",
        "DoesNotEquals": "Não é igual",
        "BeginWith": "Começa com",
        "DoesNotBeginWith": "Não começa com",
        "EndsWith": "Termina com",
        "DoesNotEndsWith": "Não termina com",
        "Contains": "Contém",
        "DoesNotContains": "Não contém",
        "GreaterThan": "Maior que",
        "GreaterThanOrEqualTo": "Melhor que ou igual a",
        "LessThan": "Menor que",
        "LessThanOrEqualTo": "Menos que ou igual a",
        "Between": "Entre",
        "NotBetween": "Não entre",
        "Before": "Antes",
        "BeforeOrEqualTo": "Antes ou igual a",
        "After": "Depois de",
        "AfterOrEqualTo": "Depois ou igual a",
        "member": "Membro",
        "label": "Rótulo",
        "date": "Encontro",
        "value": "Valor",
        "labelTextContent": "Mostrar os itens para os quais o rótulo",
        "dateTextContent": "Mostrar os itens para os quais a data",
        "valueTextContent": "Mostrar os itens para os quais",
        "And": "e",
        "Sum": "Soma",
        "Count": "Contagem",
        "DistinctCount": "Contagem Distinta",
        "Product": "produtos",
        "Avg": "Média",
        "Min": "Mín.",
        "Max": "Máx.",
        "Index": "Índice",
        "SampleStDev": "Amostra StDev",
        "PopulationStDev": "População StDev",
        "SampleVar": "Amostra Var",
        "PopulationVar": "População Var",
        "RunningTotals": "Totais em Execução",
        "DifferenceFrom": "Diferença de",
        "PercentageOfDifferenceFrom": "% de diferença de",
        "PercentageOfGrandTotal": "% do total geral",
        "PercentageOfColumnTotal": "% do total da coluna",
        "PercentageOfRowTotal": "% do total da linha",
        "PercentageOfParentTotal": "% do total pai",
        "PercentageOfParentColumnTotal": "% do total da coluna pai",
        "PercentageOfParentRowTotal": "% do total da linha pai",
        "Years": "Anos",
        "Quarters": "Quartos",
        "Months": "Meses",
        "Days": "Dias",
        "Hours": "Horas",
        "Minutes": "Minutos",
        "Seconds": "Segundos",
        "apply": "APLIQUE",
        "valueFieldSettings": "Configurações do campo Valor",
        "sourceName": "Nome do campo:",
        "sourceCaption": "Legenda do campo:",
        "summarizeValuesBy": "Resuma valores por:",
        "baseField": "Campo base:",
        "baseItem": "Item base:",
        "example": "por exemplo:",
        "editorDataLimitMsg": " mais itens. Procure refinar ainda mais.",
        "deferLayoutUpdate": "Adiar atualização de layout",
        "null": "nulo",
        "undefined": "Indefinido",
        "groupOutOfRange": "Fora de alcance",
        "fieldDropErrorAction": "O campo que você está movendo não pode ser colocado nessa área do relatório",
        "MoreOption": "Mais...",
        "memberType": "Tipo de campo",
        "selectedHierarchy": "Hierarquia pai",
        "formatString": "Formatar sequência",
        "expressionField": "Expressão",
        "olapDropText": "Exemplo: [Medidas]. [Quantidade do pedido] + ([Medidas]. [Quantidade do pedido] * 0,10)",
        "customFormat": "Digite a string de formato personalizado",
        "Measure": "A medida",
        "Dimension": "Dimensão",
        "Standard": "Padrão",
        "Currency": "Moeda",
        "Percent": "Por cento",
        "Custom": "personalizadas",
        "blank": "(Em branco)",
        "fieldTooltip": "Arraste e solte campos para criar uma expressão. E, se você deseja editar os campos calculados existentes! Você pode conseguir isso simplesmente selecionando o campo em 'Membros calculados'.",
        "fieldTitle": "Nome do campo",
        "QuarterYear": "Trimestre do ano",
        "caption": "Legenda do campo",
        "copy": "cópia de",
        "group": "Grupo",
        "numberFormatString": "Exemplo: C, P, 0000%, ### 0. ## 0 #, etc.",
        "sortAscending": "Classificar ordem crescente",
        "sortDescending": "Classificar ordem decrescente",
        "sortNone": "Classificar ordem dos dados",
        "clearCalculatedField": "Limpar informações do campo editado",
        "editCalculatedField": "Editar campo calculado",
        "selectGroup": "Selecionar grupos",
        "of": "do",
        "removeCalculatedField": "Tem certeza de que deseja excluir este campo calculado?"
    },
    "filemanager": {
        "NewFolder": "Nova pasta",
        "Upload": "Envio",
        "Delete": "Excluir",
        "Rename": "Renomear",
        "Download": "Baixar",
        "Cut": "Cortar",
        "Copy": "cópia de",
        "Paste": "Colar",
        "SortBy": "Ordenar por",
        "Refresh": "Atualizar",
        "Item-Selection": "item selecionado",
        "Items-Selection": "itens selecionados",
        "View": "Visão",
        "Details": "Detalhes",
        "SelectAll": "Selecionar tudo",
        "Open": "Aberto",
        "Tooltip-NewFolder": "Nova pasta",
        "Tooltip-Upload": "Envio",
        "Tooltip-Delete": "Excluir",
        "Tooltip-Rename": "Renomear",
        "Tooltip-Download": "Baixar",
        "Tooltip-Cut": "Cortar",
        "Tooltip-Copy": "cópia de",
        "Tooltip-Paste": "Colar",
        "Tooltip-SortBy": "Ordenar por",
        "Tooltip-Refresh": "Atualizar",
        "Tooltip-Selection": "Seleção clara",
        "Tooltip-View": "Visão",
        "Tooltip-Details": "Detalhes",
        "Tooltip-SelectAll": "Selecionar tudo",
        "Name": "Nome",
        "Size": "Tamanho",
        "DateModified": "Modificado",
        "DateCreated": "Data Criada",
        "Path": "Caminho",
        "Modified": "Modificado",
        "Created": "Criada",
        "Location": "Localização",
        "Type": "Tipo",
        "Permission": "Permissão",
        "Ascending": "Crescente",
        "Descending": "descendente",
        "None": "Nenhum",
        "View-LargeIcons": "Ícones grandes",
        "View-Details": "Detalhes",
        "Search": "Procurar",
        "Button-Ok": "Está bem",
        "Button-Cancel": "Cancelar",
        "Button-Yes": "sim",
        "Button-No": "Não",
        "Button-Create": "Crio",
        "Button-Save": "Salve ",
        "Header-NewFolder": "Pasta",
        "Content-NewFolder": "Digite o nome da sua pasta",
        "Header-Rename": "Renomear",
        "Content-Rename": "Digite seu novo nome",
        "Header-Rename-Confirmation": "Renomear confirmação",
        "Content-Rename-Confirmation": "Se você alterar uma extensão de nome de arquivo, o arquivo poderá ficar instável. Tem certeza de que deseja alterá-lo?",
        "Header-Delete": "Excluir arquivo",
        "Content-Delete": "Tem certeza de que deseja excluir este arquivo?",
        "Header-Folder-Delete": "Excluir pasta",
        "Content-Folder-Delete": "Tem certeza de que deseja excluir esta pasta?",
        "Header-Multiple-Delete": "Excluir vários arquivos",
        "Content-Multiple-Delete": "Tem certeza de que deseja excluir esses {0} arquivos?",
        "Header-Duplicate": "O arquivo / pasta existe",
        "Content-Duplicate": "{0} já existe. Deseja renomear e colar?",
        "Header-Upload": "Fazer upload de arquivos",
        "Error": "Erro",
        "Validation-Empty": "O nome do arquivo ou pasta não pode estar vazio.",
        "Validation-Invalid": "O nome do arquivo ou pasta {0} contém caracteres inválidos. Por favor, use um nome diferente. Os nomes válidos de arquivos ou pastas não podem terminar com um ponto ou espaço e não podem conter nenhum dos seguintes caracteres: \\ /: *? \"<> |",
        "Validation-NewFolder-Exists": "Um arquivo ou pasta com o nome {0} já existe.",
        "Validation-Rename-Exists": "Não é possível renomear {0} para {1}: o destino já existe.",
        "Folder-Empty": "Esta pasta está vazia",
        "File-Upload": "Arraste os arquivos aqui para fazer o upload",
        "Search-Empty": "Nenhum resultado encontrado",
        "Search-Key": "Tente com palavras-chave diferentes",
        "Filter-Empty": "Nenhum resultado encontrado",
        "Filter-Key": "Tente com filtro diferente",
        "Sub-Folder-Error": "A pasta de destino é a subpasta da pasta de origem.",
        "Access-Denied": "Acesso negado",
        "Access-Details": "Você não tem permissão para acessar esta pasta.",
        "Header-Retry": "O arquivo já existe",
        "Content-Retry": "Um arquivo com esse nome já existe nesta pasta. O que você gostaria de fazer?",
        "Button-Keep-Both": "Manter os dois",
        "Button-Replace": "Substituir",
        "Button-Skip": "Pular",
        "ApplyAll-Label": "Faça isso para todos os itens atuais",
        "KB": "KB",
        "Access-Message": "{0} não está acessível. Você precisa de permissão para executar a ação {1}.",
        "Network-Error": "Falha ao enviar XMLHTTPRequest: falha ao carregar",
        "Server-Error": "Erro de servidor: resposta inválida de"
    },
    "inplaceeditor": {
        "save": "Salve ",
        "cancel": "Cancelar",
        "loadingText": "Carregando...",
        "editIcon": "Clique para editar",
        "editAreaClick": "Clique para editar",
        "editAreaDoubleClick": "Clique duas vezes para editar"
    },
    "treegrid": {
        "Above": "Acima",
        "Below": "Abaixo",
        "AddRow": "Adicionar linha",
        "ExpandAll": "Expandir todos",
        "CollapseAll": "Recolher todos"
    },
    "colorpicker": {
        "Apply": "Aplique",
        "Cancel": "Cancelar",
        "ModeSwitcher": "Mudar de modo"
    },
    "uploader": {
        "Browse": "Squeaky toy...",
        "Clear": "Limpar",
        "Upload": "Envio",
        "dropFilesHint": "Ou solte arquivos aqui",
        "invalidMaxFileSize": "O tamanho do arquivo é muito grande",
        "invalidMinFileSize": "O tamanho do arquivo é muito pequeno",
        "invalidFileType": "Tipo de arquivo não é permitido",
        "uploadFailedMessage": "Falha no upload do arquivo",
        "uploadSuccessMessage": "Arquivo enviado com sucesso",
        "removedSuccessMessage": "Arquivo removido com sucesso",
        "removedFailedMessage": "Não foi possível remover o arquivo",
        "inProgress": "Enviando",
        "readyToUploadMessage": "Pronto para carregar",
        "abort": "Abortar",
        "remove": "Retirar",
        "cancel": "Cancelar",
        "delete": "Excluir arquivo",
        "pauseUpload": "Upload de arquivo pausado",
        "pause": "Pausa",
        "resume": "Currículo",
        "retry": "Repetir",
        "fileUploadCancel": "Upload de arquivo cancelado"
    },
    "numerictextbox": {
        "incrementTitle": "Valor do incremento",
        "decrementTitle": "Valor decrescente"
    },
    "slider": {
        "incrementTitle": "Aumentar",
        "decrementTitle": "Diminuir"
    },
    "formValidator": {
        "required": "Este campo é obrigatório.",
        "email": "Por favor insira um endereço de e-mail válido.",
        "url": "Por favor, insira um URL válido.",
        "date": "Por favor insira uma data válida.",
        "dateIso": "Digite uma data válida (ISO).",
        "creditcard": "Digite o número do cartão válido",
        "number": "por favor insira um número válido.",
        "digits": "Digite apenas dígitos.",
        "maxLength": "Por favor, não insira mais que {0} caracteres.",
        "minLength": "Digite pelo menos {0} caracteres.",
        "rangeLength": "Digite um valor entre {0} e {1} caracteres.",
        "range": "Digite um valor entre {0} e {1}.",
        "max": "Digite um valor menor ou igual a {0}.",
        "min": "Por favor, insira um valor maior ou igual a {0}.",
        "regex": "Digite um valor correto.",
        "tel": "Por favor insira um número de telefone válido.",
        "pattern": "Digite um valor de padrão correto.",
        "equalTo": "Digite o texto de correspondência válido"
    },
    "richtexteditor": {
        "alignments": "Alinhamentos",
        "justifyLeft": "Alinhar à esquerda",
        "justifyCenter": "Alinhar ao centro",
        "justifyRight": "Alinhar à direita",
        "justifyFull": "Alinhar justificar",
        "fontName": "Nome da fonte",
        "fontSize": "Tamanho da fonte",
        "fontColor": "Cor da fonte",
        "backgroundColor": "Cor de fundo",
        "bold": "Negrito",
        "italic": "itálico",
        "underline": "Sublinhado",
        "strikethrough": "Tachado",
        "clearFormat": "Limpar formato",
        "clearAll": "Limpar tudo",
        "cut": "Cortar",
        "copy": "cópia de",
        "paste": "Colar",
        "unorderedList": "Lista com marcadores",
        "orderedList": "Lista numerada",
        "indent": "Aumentar recuo",
        "outdent": "Recuar devagar",
        "undo": "Desfazer",
        "redo": "Refazer",
        "superscript": "Sobrescrito",
        "subscript": "Subscrito",
        "createLink": "Insira o hyperlink",
        "openLink": "Link aberto",
        "editLink": "Editar Link",
        "removeLink": "Remover link",
        "image": "Inserir Imagem",
        "replace": "Substituir",
        "align": "Alinhar",
        "caption": "Legenda da imagem",
        "remove": "Retirar",
        "insertLink": "Inserir link",
        "display": "Exibição",
        "altText": "Texto Alternativo",
        "dimension": "Alterar tamanho",
        "fullscreen": "Maximizar",
        "maximize": "Maximizar",
        "minimize": "Minimizar",
        "lowerCase": "Minúsculas",
        "upperCase": "Maiúsculas",
        "print": "Impressão",
        "formats": "Formatos",
        "sourcecode": "Visualização de código",
        "preview": "Pré-visualização",
        "viewside": "ViewSide",
        "insertCode": "Insira Código",
        "linkText": "Texto de exibição",
        "linkTooltipLabel": "Título",
        "linkWebUrl": "Endereço da Web",
        "linkTitle": "Digite um título",
        "linkurl": "http://example.com",
        "linkOpenInNewWindow": "Abrir link em nova janela",
        "linkHeader": "Inserir link",
        "dialogInsert": "Inserir",
        "dialogCancel": "Cancelar",
        "dialogUpdate": "Atualizar",
        "imageHeader": "Inserir Imagem",
        "imageLinkHeader": "Você também pode fornecer um link da web",
        "mdimageLink": "Forneça um URL para sua imagem",
        "imageUploadMessage": "Solte a imagem aqui ou navegue para fazer upload",
        "imageDeviceUploadMessage": "Clique aqui para fazer o upload",
        "imageAlternateText": "Texto alternativo",
        "alternateHeader": "Texto Alternativo",
        "browse": "Squeaky toy",
        "imageUrl": "http://example.com/image.png",
        "imageCaption": "Rubrica",
        "imageSizeHeader": "Tamanho da imagem",
        "imageHeight": "Altura",
        "imageWidth": "Largura",
        "textPlaceholder": "Inserir texto",
        "inserttablebtn": "Insira a tabela",
        "tabledialogHeader": "Insira a tabela",
        "tableWidth": "Largura",
        "cellpadding": "Cell Padding",
        "cellspacing": "Espaçamento celular",
        "columns": "Numero de colunas",
        "rows": "Numero de linhas",
        "tableRows": "Linhas de tabela",
        "tableColumns": "Colunas da tabela",
        "tableCellHorizontalAlign": "Alinhamento horizontal da célula da tabela",
        "tableCellVerticalAlign": "Alinhamento vertical da célula da tabela",
        "createTable": "Criar a tabela",
        "removeTable": "Remover tabela",
        "tableHeader": "Cabeçalho da tabela",
        "tableRemove": "Tabela Remover",
        "tableCellBackground": "Fundo da célula da tabela",
        "tableEditProperties": "Propriedades de edição de tabela",
        "styles": "Estilos",
        "insertColumnLeft": "Inserir coluna à esquerda",
        "insertColumnRight": "Inserir coluna à direita",
        "deleteColumn": "Excluir coluna",
        "insertRowBefore": "Inserir linha antes",
        "insertRowAfter": "Inserir linha após",
        "deleteRow": "Excluir linha",
        "tableEditHeader": "Editar tabela",
        "TableHeadingText": "Título",
        "TableColText": "Col",
        "imageInsertLinkHeader": "Inserir link",
        "editImageHeader": "Editar imagem",
        "alignmentsDropDownLeft": "Alinhar à esquerda",
        "alignmentsDropDownCenter": "Alinhar ao centro",
        "alignmentsDropDownRight": "Alinhar à direita",
        "alignmentsDropDownJustify": "Alinhar justificar",
        "imageDisplayDropDownInline": "Na linha",
        "imageDisplayDropDownBreak": "Pausa",
        "tableInsertRowDropDownBefore": "Inserir linha antes",
        "tableInsertRowDropDownAfter": "Inserir linha após",
        "tableInsertRowDropDownDelete": "Excluir linha",
        "tableInsertColumnDropDownLeft": "Inserir coluna à esquerda",
        "tableInsertColumnDropDownRight": "Inserir coluna à direita",
        "tableInsertColumnDropDownDelete": "Excluir coluna",
        "tableVerticalAlignDropDownTop": "Alinhar parte superior",
        "tableVerticalAlignDropDownMiddle": "Alinhar ao meio",
        "tableVerticalAlignDropDownBottom": "Alinhar parte inferior",
        "tableStylesDropDownDashedBorder": "Fronteiras tracejadas",
        "tableStylesDropDownAlternateRows": "Linhas alternativas",
        "pasteFormat": "Colar formato",
        "pasteFormatContent": "Escolha a ação de formatação",
        "plainText": "Texto simples",
        "cleanFormat": "Limpar \\ limpo",
        "keepFormat": "Manter",
        "pasteDialogOk": "Está bem",
        "pasteDialogCancel": "Cancelar",
        "fileManager": "Gerenciador de arquivos",
        "fileDialogHeader": "Navegador de arquivos"
    },
    "diagram": {
        "Copy": "cópia de",
        "Cut": "Cortar",
        "Paste": "Colar",
        "Undo": "Desfazer",
        "Redo": "Refazer",
        "SelectAll": "Selecionar tudo",
        "Grouping": "Agrupamento",
        "Group": "Grupo",
        "UnGroup": "Desagrupar",
        "Order": "Ordem",
        "BringToFront": "Traga para frente",
        "MoveForward": "Siga em frente",
        "SendToBack": "Enviar para trás",
        "SendBackward": "Enviar para trás"
    },
    "DocumentEditor": {
        "Table": "Mesa",
        "Row": "Linha",
        "Cell": "Célula",
        "Ok": "Está bem",
        "Cancel": "Cancelar",
        "Size": "Tamanho",
        "Preferred Width": "Largura preferida",
        "Points": "Pontos",
        "Percent": "Por cento",
        "Measure in": "Medida em",
        "Alignment": "Alinhamento",
        "Left": "Esquerda",
        "Center": "Centro",
        "Right": "Certo",
        "Justify": "Justificar",
        "Indent from left": "Recuar da esquerda",
        "Borders and Shading": "Bordas e sombreamento",
        "Options": "Opções",
        "Specify height": "Especifique a altura",
        "At least": "Finalmente",
        "Exactly": "Exatamente",
        "Row height is": "A altura da linha é",
        "Allow row to break across pages": "Permitir quebra de linha nas páginas",
        "Repeat as header row at the top of each page": "Repita como linha de cabeçalho na parte superior de cada página",
        "Vertical alignment": "Alinhamento vertical",
        "Top": "Topo",
        "Bottom": "Inferior",
        "Default cell margins": "Margens de célula padrão",
        "Default cell spacing": "Espaçamento de célula padrão",
        "Allow spacing between cells": "Permitir espaçamento entre células",
        "Cell margins": "Margens da célula",
        "Same as the whole table": "Igual à tabela inteira",
        "Borders": "Fronteiras",
        "None": "Nenhum",
        "Style": "Estilo",
        "Width": "Largura",
        "Height": "Altura",
        "Letter": "Carta",
        "Tabloid": "Tablóide",
        "Legal": "Legal",
        "Statement": "Declaração",
        "Executive": "Executivo",
        "A3": "A3",
        "A4": "A4",
        "A5": "A5",
        "B4": "B4",
        "B5": "B5",
        "Custom Size": "Tamanho personalizado",
        "Different odd and even": "Diferente e ímpar",
        "Different first page": "Primeira página diferente",
        "From edge": "De borda",
        "Header": "Cabeçalho",
        "Footer": "Rodapé",
        "Margin": "Margens",
        "Paper": "Papel",
        "Layout": "Layout",
        "Orientation": "Orientação",
        "Landscape": "Panorama",
        "Portrait": "Retrato",
        "Table Of Contents": "Índice",
        "Show page numbers": "Mostrar números de página",
        "Right align page numbers": "Alinhar à direita os números das páginas",
        "Nothing": "Nada",
        "Tab leader": "Líder da guia",
        "Show levels": "Mostrar níveis",
        "Use hyperlinks instead of page numbers": "Use hiperlinks em vez de números de página",
        "Build table of contents from": "Crie um índice a partir de",
        "Styles": "Estilos",
        "Available styles": "Estilos disponíveis",
        "TOC level": "Nível TOC",
        "Heading": "Título",
        "List Paragraph": "Parágrafo da lista",
        "Normal": "Normal",
        "Outline levels": "Níveis de estrutura de tópicos",
        "Table entry fields": "Campos de entrada da tabela",
        "Modify": "Modificar",
        "Color": "Cor",
        "Setting": "Configuração",
        "Box": "Caixa",
        "All": "Todos",
        "Custom": "personalizadas",
        "Preview": "Pré-visualização",
        "Shading": "Sombreamento",
        "Fill": "Preencher",
        "Apply To": "Aplicar a",
        "Table Properties": "Propriedades da tabela",
        "Cell Options": "Opções de célula",
        "Table Options": "Opções de tabela",
        "Insert Table": "Insira a tabela",
        "Number of columns": "Numero de colunas",
        "Number of rows": "Numero de linhas",
        "Text to display": "Texto a ser exibido",
        "Address": "Endereço",
        "Insert Hyperlink": "Insira o hyperlink",
        "Edit Hyperlink": "Editar hiperlink",
        "Insert": "Inserir",
        "General": "Geral",
        "Indentation": "Indentação",
        "Before text": "Antes do texto",
        "Special": "Especial",
        "First line": "Primeira linha",
        "Hanging": "Suspensão",
        "After text": "Após o texto",
        "By": "Por",
        "Before": "Antes",
        "Line Spacing": "Espaçamento entre linhas",
        "After": "Depois de",
        "At": "At",
        "Multiple": "Múltiplo",
        "Spacing": "Espaçamento",
        "Define new Multilevel list": "Definir nova lista multinível",
        "List level": "Nível da lista",
        "Choose level to modify": "Escolha o nível para modificar",
        "Level": "Nível",
        "Number format": "Formato numérico",
        "Number style for this level": "Estilo numérico para este nível",
        "Enter formatting for number": "Digite a formatação para o número",
        "Start at": "Começa ás",
        "Restart list after": "Reinicie a lista depois",
        "Position": "Posição",
        "Text indent at": "Recuo do texto em",
        "Aligned at": "Alinhado em",
        "Follow number with": "Siga o número com",
        "Tab character": "Caractere de tabulação",
        "Space": "Espaço",
        "Arabic": "árabe",
        "UpRoman": "UpRoman",
        "LowRoman": "LowRoman",
        "UpLetter": "UpLetter",
        "LowLetter": "LowLetter",
        "Number": "Número",
        "Leading zero": "Zero inicial",
        "Bullet": "Bala",
        "Ordinal": "Ordinal",
        "Ordinal Text": "Texto Ordinal",
        "For East": "Para o leste",
        "No Restart": "Não reiniciar",
        "Font": "Fonte",
        "Font style": "Estilo de fonte",
        "Underline style": "Estilo sublinhado",
        "Font color": "Cor da fonte",
        "Effects": "Efeitos",
        "Strikethrough": "Tachado",
        "Superscript": "Sobrescrito",
        "Subscript": "Subscrito",
        "Double strikethrough": "Tachado duplo",
        "Regular": "Regular",
        "Bold": "Negrito",
        "Italic": "itálico",
        "Cut": "Cortar",
        "Copy": "cópia de",
        "Paste": "Colar",
        "Hyperlink": "Hiperlink",
        "Open Hyperlink": "Abrir hiperlink",
        "Copy Hyperlink": "Copiar hiperlink",
        "Remove Hyperlink": "Remover hiperlink",
        "Paragraph": "Parágrafo",
        "Merge Cells": "Mesclar células",
        "Insert Above": "Inserir acima",
        "Insert Below": "Inserir abaixo",
        "Insert Left": "Inserir à esquerda",
        "Insert Right": "Inserir à direita",
        "Delete": "Excluir",
        "Delete Table": "Excluir tabela",
        "Delete Row": "Excluir linha",
        "Delete Column": "Excluir coluna",
        "File Name": "Nome do arquivo",
        "Format Type": "Tipo de formato",
        "Save": "Salve ",
        "Navigation": "Navegação",
        "Results": "Resultados",
        "Replace": "Substituir",
        "Replace All": "Substitua tudo",
        "We replaced all": "Nós substituímos todos",
        "Find": "Encontrar",
        "No matches": "Sem combinações",
        "All Done": "Tudo feito",
        "Result": "Resultado",
        "of": "do",
        "instances": "instâncias",
        "with": "com",
        "Click to follow link": "Clique para seguir o link",
        "Continue Numbering": "Continuar numeração",
        "Bookmark name": "Nome do marcador",
        "Close": "Fechar",
        "Restart At": "Reiniciar em",
        "Properties": "Propriedades",
        "Name": "Nome",
        "Style type": "Tipo de estilo",
        "Style based on": "Estilo baseado em",
        "Style for following paragraph": "Estilo para o parágrafo a seguir",
        "Formatting": "Formatação",
        "Numbering and Bullets": "Numeração e marcadores",
        "Numbering": "Numeração",
        "Update Field": "Atualizar campo",
        "Edit Field": "Editar campo",
        "Bookmark": "marca páginas",
        "Page Setup": "Configurações da página",
        "No bookmarks found": "Nenhum marcador encontrado",
        "Number format tooltip information": "Formato de número de nível único: </br> [PREFIX]% [LEVELNUMBER] [SUFFIX] </br> Por exemplo, capítulo% 1. exibirá numeração como </br> Capítulo 1. Item </br> Capítulo 2. Item </br> ... </br> Capítulo N. Item </br> </br> Formato de número multinível: </ br > [PREFIXO]% [NÍVEL NÍVEL] [SUFIXO] + [PREFIXO]% [NÚMERO NÍVEL] [SUFIXO] </br> Por exemplo,% 1.% 2. exibirá numeração como </br> 1.1. Item </br> 1.2. Item </br>… </br> 1.N. Item",
        "Format": "Formato",
        "Create New Style": "Criar novo estilo",
        "Modify Style": "Modificar estilo",
        "New": "Novo",
        "Bullets": "Balas",
        "Use bookmarks": "Usar marcadores",
        "Table of Contents": "Índice"
    },
    "barcode": {},
    "datamatrix": {},
    "qrcode": {},
    "drawing": {},
    "schedule": {
        "day": "Dia",
        "week": "Semana",
        "workWeek": "Semana de trabalho",
        "month": "Mês",
        "year": "Ano",
        "agenda": "Agenda",
        "weekAgenda": "Agenda da semana",
        "workWeekAgenda": "Agenda da Semana de Trabalho",
        "monthAgenda": "Agenda do mês",
        "today": "Hoje",
        "noEvents": "Sem eventos",
        "emptyContainer": "Não há eventos agendados para este dia.",
        "allDay": "Dia todo",
        "start": "Começar",
        "end": "Fim",
        "more": "Mais",
        "close": "Fechar",
        "cancel": "Cancelar",
        "noTitle": "(Sem título)",
        "delete": "Excluir",
        "deleteEvent": "Este evento",
        "deleteMultipleEvent": "Excluir vários eventos",
        "selectedItems": "Itens selecionados",
        "deleteSeries": "Série inteira",
        "edit": "Editar",
        "editSeries": "Série inteira",
        "editEvent": "Este evento",
        "createEvent": "Crio",
        "subject": "Sujeito",
        "addTitle": "Adicionar título",
        "moreDetails": "Mais detalhes",
        "save": "Salve ",
        "editContent": "Como você gostaria de alterar o compromisso na série?",
        "deleteContent": "Tem certeza de que deseja excluir este evento?",
        "deleteMultipleContent": "Tem certeza de que deseja excluir os eventos selecionados?",
        "newEvent": "Novo evento",
        "title": "Título",
        "location": "Localização",
        "description": "Descrição",
        "timezone": "Fuso horário",
        "startTimezone": "Iniciar fuso horário",
        "endTimezone": "Fuso horário final",
        "repeat": "Repetir",
        "saveButton": "Salve ",
        "cancelButton": "Cancelar",
        "deleteButton": "Excluir",
        "recurrence": "Recorrência",
        "wrongPattern": "O padrão de recorrência não é válido.",
        "seriesChangeAlert": "Deseja cancelar as alterações feitas em instâncias específicas desta série e associá-las à série inteira novamente?",
        "createError": "A duração do evento deve ser menor que a frequência com que ele ocorre. Diminua a duração ou altere o padrão de recorrência no editor de eventos de recorrência.",
        "sameDayAlert": "Duas ocorrências do mesmo evento não podem ocorrer no mesmo dia.",
        "editRecurrence": "Editar recorrência",
        "repeats": "Repete",
        "alert": "Alerta",
        "startEndError": "A data final selecionada ocorre antes da data de início.",
        "invalidDateError": "O valor da data inserida é inválido.",
        "blockAlert": "Os eventos não podem ser agendados dentro do intervalo de tempo bloqueado.",
        "ok": "Está bem",
        "yes": "sim",
        "no": "Não",
        "occurrence": "Ocorrência",
        "series": "Series",
        "previous": "Anterior",
        "next": "Próximo",
        "timelineDay": "Dia da linha do tempo",
        "timelineWeek": "Semana da Linha do Tempo",
        "timelineWorkWeek": "Semana de trabalho da linha do tempo",
        "timelineMonth": "Mês da linha do tempo",
        "timelineYear": "Ano da Linha do Tempo",
        "editFollowingEvent": "Eventos seguintes",
        "deleteTitle": "Excluir evento",
        "editTitle": "Editar evento",
        "beginFrom": "Começar de",
        "endAt": "Termina em",
        "expandAllDaySection": "Seção de expansão para o dia todo",
        "collapseAllDaySection": "Recolher a seção do dia inteiro"
    },
    "recurrenceeditor": {
        "none": "Nenhum",
        "daily": "Diariamente",
        "weekly": "Semanal",
        "monthly": "Por mês",
        "month": "Mês",
        "yearly": "Anual",
        "never": "Nunca",
        "until": "Até",
        "count": "Contagem",
        "first": "Primeiro",
        "second": "Segundo",
        "third": "Terceiro",
        "fourth": "Quarto",
        "last": "Último",
        "repeat": "Repetir",
        "repeatEvery": "Repita cada",
        "on": "Repetir em",
        "end": "Fim",
        "onDay": "Dia",
        "days": "Dias)",
        "weeks": "Semana (s)",
        "months": "Mês (es)",
        "years": "Anos)",
        "every": "cada",
        "summaryTimes": "tempo (s)",
        "summaryOn": "em",
        "summaryUntil": "até",
        "summaryRepeat": "Repete",
        "summaryDay": "dias)",
        "summaryWeek": "semana (s)",
        "summaryMonth": "mês (es)",
        "summaryYear": "anos)",
        "monthWeek": "Mês Semana",
        "monthPosition": "Posição do mês",
        "monthExpander": "Expansor do mês",
        "yearExpander": "Expansor do ano",
        "repeatInterval": "Intervalo de repetição"
    },
    "spreadsheet": {
        "InsertingEmptyValue": "O valor de referência não é válido.",
        "FindValue": "Encontre valor",
        "ReplaceValue": "Substituir valor",
        "FindReplaceTooltip": "Localizar e substituir",
        "ByRow": " Por linhas",
        "ByColumn": "Por colunas",
        "MatchExactCellElements": "Corresponder ao conteúdo exato da célula",
        "EntercellAddress": "Digite o endereço da célula",
        "FindAndReplace": "Localizar e substituir",
        "ReplaceAllEnd": " correspondências substituídas por",
        "FindNextBtn": "Encontre o próximo",
        "FindPreviousBtn": "Localizar Anterior",
        "ReplaceBtn": "Substituir",
        "ReplaceAllBtn": "Substitua tudo",
        "GotoHeader": "Vamos para",
        "GotoSpecialHeader": "Ir para Especial",
        "Sheet": "Folha",
        "SearchWithin": "Pesquisar dentro",
        "SearchBy": "Procurar por",
        "Reference": "Referência",
        "Workbook": "Pasta de trabalho",
        "NoElements": "Não foi possível encontrar o que você estava procurando. Clique nas opções para obter mais maneiras de pesquisar",
        "FindWhat": "Encontre o que",
        "ReplaceWith": "Substituir com",
        "EnterValue": "Digite o valor",
        "Cut": "Cortar",
        "Copy": "cópia de",
        "Paste": "Colar",
        "PasteSpecial": "Colar especial",
        "All": "Todos",
        "Values": "Valores",
        "Formats": "Formatos",
        "Font": "Fonte",
        "FontSize": "Tamanho da fonte",
        "Bold": "Negrito",
        "Italic": "itálico",
        "Underline": "Sublinhado",
        "Strikethrough": "Tachado",
        "TextColor": "Cor do texto",
        "FillColor": "Cor de preenchimento",
        "HorizontalAlignment": "Alinhamento horizontal",
        "AlignLeft": "Alinhar à esquerda",
        "AlignCenter": "Centro",
        "AlignRight": "Alinhar à direita",
        "VerticalAlignment": "Alinhamento vertical",
        "AlignTop": "Alinhar parte superior",
        "AlignMiddle": "Alinhar ao meio",
        "AlignBottom": "Alinhar parte inferior",
        "MergeCells": "Mesclar células",
        "MergeAll": "Mesclar tudo",
        "MergeHorizontally": "Mesclar horizontalmente",
        "MergeVertically": "Mesclar verticalmente",
        "Unmerge": "Unmerge",
        "UnmergeCells": "Desmembrar células",
        "SelectMergeType": "Selecione o Tipo de Mesclagem",
        "MergeCellsAlert": "A mesclagem de células preservará apenas o valor superior esquerdo (Superior). Mesclar mesmo assim?",
        "PasteMergeAlert": "Não podemos fazer isso em uma célula de mesclagem.",
        "Borders": "Fronteiras",
        "TopBorders": "Fronteiras superiores",
        "LeftBorders": "Fronteiras Esquerdas",
        "RightBorders": "Fronteiras direitas",
        "BottomBorders": "Fronteiras inferiores",
        "AllBorders": "Todas as Fronteiras",
        "HorizontalBorders": "Fronteiras horizontais",
        "VerticalBorders": "Bordas verticais",
        "OutsideBorders": "Fora das fronteiras",
        "InsideBorders": "Dentro das fronteiras",
        "NoBorders": "Sem Fronteiras",
        "BorderColor": "Cor da borda",
        "BorderStyle": "Estilo de borda",
        "InsertFunction": "Inserir Função",
        "Insert": "Inserir",
        "Delete": "Excluir",
        "Rename": "Renomear",
        "Hide": "ocultar",
        "Unhide": "Mostrar",
        "NameBox": "Name Box",
        "ShowHeaders": "Mostrar cabeçalhos",
        "HideHeaders": "Ocultar cabeçalhos",
        "ShowGridLines": "Mostrar linhas de grade",
        "HideGridLines": "Ocultar linhas de grade",
        "FreezePanes": "Congelar painéis",
        "FreezeRows": "Congelar linhas",
        "FreezeColumns": "Congelar Colunas",
        "UnfreezePanes": "Descongelar painéis",
        "UnfreezeRows": "Descongelar linhas",
        "UnfreezeColumns": "Descongelar colunas",
        "AddSheet": "Adicionar folha",
        "ListAllSheets": "Listar todas as folhas",
        "FullScreen": "Tela cheia",
        "CollapseToolbar": "Recolher a barra de ferramentas",
        "ExpandToolbar": "Expandir barra de ferramentas",
        "CollapseFormulaBar": "Recolher barra de fórmulas",
        "ExpandFormulaBar": "Expandir barra de fórmulas",
        "File": "Arquivo",
        "Home": "Casa",
        "Formulas": "Fórmulas",
        "View": "Visão",
        "New": "Novo",
        "Open": "Aberto",
        "SaveAs": "Salvar como",
        "ExcelXlsx": "Microsoft Excel",
        "ExcelXls": "Microsoft Excel 97-2003",
        "CSV": "Valores Separados Por Virgula",
        "FormulaBar": "Barra de Fórmula",
        "Sort": "Ordenar",
        "SortAscending": "Crescente",
        "SortDescending": "descendente",
        "CustomSort": "Classificação personalizada",
        "AddColumn": "Adicionar coluna",
        "ContainsHeader": "Os dados contêm cabeçalho",
        "CaseSensitive": "Maiúsculas e Minúsculas",
        "SortBy": "Ordenar por",
        "ThenBy": "Então por",
        "SelectAColumn": "Selecione uma coluna",
        "SortEmptyFieldError": "Todos os critérios de classificação devem ter uma coluna especificada. Verifique os critérios de classificação selecionados e tente novamente.",
        "SortDuplicateFieldError": " está sendo classificado por valores mais de uma vez. Exclua os critérios de classificação duplicados e tente novamente.",
        "SortOutOfRangeError": "Selecione uma célula ou intervalo dentro do intervalo usado e tente novamente.",
        "HideRow": "Ocultar linha",
        "HideRows": "Ocultar linhas",
        "UnHideRows": "Mostrar linhas",
        "HideColumn": "Ocultar coluna",
        "HideColumns": "Ocultar colunas",
        "UnHideColumns": "Ocultar colunas",
        "InsertRow": "Inserir linha",
        "InsertRows": "Inserir linhas",
        "Above": "Acima",
        "Below": "Abaixo",
        "InsertColumn": "Inserir coluna",
        "InsertColumns": "Inserir colunas",
        "Before": "Antes",
        "After": "Depois de",
        "DeleteRow": "Excluir linha",
        "DeleteRows": "Excluir linhas",
        "DeleteColumn": "Excluir coluna",
        "DeleteColumns": "Excluir colunas",
        "Ok": "Está bem",
        "Close": "Fechar",
        "Cancel": "Cancelar",
        "Apply": "Aplique",
        "MoreColors": "Mais cores",
        "StandardColors": "Cores padrão",
        "General": "Geral",
        "Number": "Número",
        "Currency": "Moeda",
        "Accounting": "Contabilidade",
        "ShortDate": "Encontro curto",
        "LongDate": "Data Longa",
        "Time": "Tempo",
        "Percentage": "Percentagem",
        "Fraction": "Fração",
        "Scientific": "Científico",
        "Text": "Texto",
        "NumberFormat": "Formato numérico",
        "MobileFormulaBarPlaceHolder": "Digite o valor ou a fórmula",
        "PasteAlert": "Você não pode colar isso aqui, porque a área de cópia e a área de colar não têm o mesmo tamanho. Tente colar em um intervalo diferente.",
        "DestroyAlert": "Tem certeza de que deseja destruir a pasta de trabalho atual sem salvar e criar uma nova pasta de trabalho?",
        "SheetRenameInvalidAlert": "O nome da folha contém caracteres inválidos.",
        "SheetRenameEmptyAlert": "O nome da folha não pode estar vazio.",
        "SheetRenameAlreadyExistsAlert": "O nome da planilha já existe. Por favor, insira outro nome.",
        "DeleteSheetAlert": "Tem certeza de que deseja excluir esta planilha?",
        "DeleteSingleLastSheetAlert": "Uma pasta de trabalho deve conter pelo menos uma planilha visível.",
        "PickACategory": "Escolha uma categoria",
        "Description": "Descrição",
        "UnsupportedFile": "Arquivo não suportado",
        "InvalidUrl": "URL inválida",
        "SUM": "Adiciona uma série de números e / ou células.",
        "SUMIF": "Adiciona as células com base na condição especificada.",
        "SUMIFS": "Adiciona as células com base nas condições especificadas.",
        "ABS": "Retorna o valor de um número sem seu sinal.",
        "RAND": "Retorna um número aleatório entre 0 e 1.",
        "RANDBETWEEN": "Retorna um número inteiro aleatório com base nos valores especificados.",
        "FLOOR": "Arredonda um número para baixo até o múltiplo mais próximo de um determinado fator.",
        "CEILING": "Arredonda um número até o múltiplo mais próximo de um determinado fator.",
        "PRODUCT": "Multiplica uma série de números e / ou células.",
        "AVERAGE": "Calcula a média para a série de números e / ou células, excluindo texto.",
        "AVERAGEIF": "Calcula a média para as células com base no critério especificado.",
        "AVERAGEIFS": "Calcula a média para as células com base nas condições especificadas.",
        "AVERAGEA": "Calcula a média das células que avaliam TRUE como 1, texto e FALSE como 0.",
        "COUNT": "Conta as células que contêm valores numéricos em um intervalo.",
        "COUNTIF": "Conta as células com base na condição especificada.",
        "COUNTIFS": "Conta as células com base nas condições especificadas.",
        "COUNTA": "Conta as células que contêm valores em um intervalo.",
        "MIN": "Retorna o menor número de argumentos fornecidos.",
        "MAX": "Retorna o maior número de argumentos fornecidos.",
        "DATE": "Retorna a data com base no ano, mês e dia.",
        "DAY": "Retorna o dia a partir da data especificada.",
        "DAYS": "Retorna o número de dias entre duas datas.",
        "IF": "Retorna valor com base na expressão especificada.",
        "IFS": "Retorna valor com base nas múltiplas expressões fornecidas.",
        "CalculateAND": "Retorna TRUE se todos os argumentos forem TRUE, caso contrário, retorna FALSE.",
        "CalculateOR": "Retorna VERDADEIRO se algum dos argumentos for VERDADEIRO; caso contrário, retornará FALSO.",
        "IFERROR": "Retorna valor se nenhum erro for encontrado, ele retornará o valor especificado.",
        "CHOOSE": "Retorna um valor da lista de valores, com base no número do índice.",
        "INDEX": "Retorna um valor da célula em um determinado intervalo com base no número da linha e da coluna.",
        "FIND": "Retorna a posição de uma string dentro de outra string, que diferencia maiúsculas de minúsculas",
        "CONCATENATE": "Combina duas ou mais seqüências de caracteres juntas.",
        "CONCAT": "Concatena uma lista ou um intervalo de cadeias de texto.",
        "SUBTOTAL": "Retorna o subtotal para um intervalo usando o número da função fornecido.",
        "RADIANS": "Converte graus em radianos.",
        "MATCH": "Retorna a posição relativa de um valor especificado em um determinado intervalo.",
        "SLOPE": "Retorna a inclinação da linha a partir da regressão linear dos pontos de dados.",
        "INTERCEPT": "Calcula o ponto da linha de interceptação em Y por regressão linear.",
        "TEXT": "Converte um valor em texto no formato de número especificado.",
        "DefineNameExists": "Este nome já existe, tente um nome diferente.",
        "CircularReference": "Quando uma fórmula se refere a uma ou mais referências circulares, isso pode resultar em um cálculo incorreto.",
        "SORT": "Classifica um intervalo de uma matriz",
        "ShowRowsWhere": "Mostrar linhas onde:",
        "OR": "OU",
        "AND": "E",
        "CustomFilterDatePlaceHolder": "Escolha uma data",
        "CustomFilterPlaceHolder": "Digite o valor",
        "CustomFilter": "Filtro customizado",
        "Between": "Entre",
        "MatchCase": "Caso de compatibilidade",
        "DateTimeFilter": "Filtros DateTime",
        "Undo": "Desfazer",
        "Redo": "Refazer",
        "ClearAllFilter": "Limpar",
        "ReapplyFilter": "Reaplicar",
        "DateFilter": "Filtros de data",
        "TextFilter": "Filtros de texto",
        "NumberFilter": "Filtros numéricos",
        "ClearFilter": "Filtro limpo",
        "NoResult": "Nenhuma equivalência encontrada",
        "FilterFalse": "Falso",
        "FilterTrue": "Verdade",
        "Blanks": "Espaços em branco",
        "SelectAll": "Selecionar tudo",
        "GreaterThanOrEqual": "Maior que ou igual",
        "GreaterThan": "Maior que",
        "LessThanOrEqual": "Menor ou igual",
        "LessThan": "Menor que",
        "NotEqual": "Não igual",
        "Equal": "Igual",
        "Contains": "Contém",
        "EndsWith": "Termina com",
        "StartsWith": "Começa com",
        "ClearButton": "Limpar",
        "FilterButton": "Filtro",
        "CancelButton": "Cancelar",
        "OKButton": "Está bem",
        "Search": "Procurar",
        "DataValidation": "Data de validade",
        "CLEARALL": "LIMPAR TUDO",
        "APPLY": "APLIQUE",
        "CellRange": "Cell Range",
        "Allow": "Permitir",
        "Data": "Dados",
        "Minimum": "Mínimo",
        "Maximum": "Máximo",
        "IgnoreBlank": "Ignorar em branco",
        "WholeNumber": "Número inteiro",
        "Decimal": "Decimal",
        "Date": "Encontro",
        "TextLength": "Comprimento do texto",
        "List": "Lista",
        "NotBetween": "Não entre",
        "EqualTo": "Igual a",
        "NotEqualTo": "Diferente de",
        "Greaterthan": "Maior que",
        "Lessthan": "Menor que",
        "GreaterThanOrEqualTo": "Melhor que ou igual a",
        "LessThanOrEqualTo": "Menos que ou igual a",
        "InCellDropDown": "Menu suspenso na célula",
        "Sources": "Fontes",
        "Value": "Valor",
        "Retry": "Repetir",
        "DialogError": "A fonte da lista deve ser uma referência a uma única linha ou coluna.",
        "ValidationError": "Este valor não corresponde às restrições de validação de dados definidas para a célula.",
        "ListLengthError": "Os valores da lista permitem apenas até 256 caracteres",
        "ProtectSheet": "Proteger folha",
        "UnprotectSheet": "Desproteger planilha",
        "SelectCells": "Selecionar células",
        "FormatCells": "Formatar celulas",
        "FormatRows": "Formatar linhas",
        "Format Columns": "Formatar colunas",
        "InsertLinks": "Inserir links",
        "ProtectContent": "Proteger o conteúdo de células bloqueadas",
        "ProtectAllowUser": "Permita que todos os usuários desta planilha:",
        "EditAlert": "A célula que você está tentando alterar está protegida. Para fazer alterações, desproteja a folha.",
        "ClearValidation": "Limpar validação",
        "ISNUMBER": "Retorna true quando o valor analisa como um valor numérico.",
        "Round": "Arredonda um número para um número especificado de dígitos.",
        "GEOMEAN": "Retorna a média geométrica de uma matriz ou intervalo de dados positivos.",
        "POWER": "Retorna o resultado de um número elevado ao poder",
        "LOG": "Retorna o logaritmo de um número para a base que você especificar.",
        "TRUNC": "Retorna o valor truncado de um número para um número especificado de casas decimais.",
        "EXP": "Retorna e elevado à potência do número fornecido.",
        "HighlightCellsRules": "Regras de destaque das células",
        "CFEqualTo": "Igual a",
        "TextThatContains": "Texto que contém",
        "ADateOccuring": "Uma data ocorrendo",
        "DuplicateValues": "Valores Duplicados",
        "TopBottomRules": "Regras superior / inferior",
        "Top10Items": "10 principais itens",
        "Top10": "10 principais",
        "Bottom10Items": "10 itens inferiores",
        "Bottom10": "10 inferiores",
        "AboveAverage": "Acima da média",
        "BelowAverage": "Abaixo da média",
        "FormatCellsGreaterThan": "Formate células MAIORES QUE:",
        "FormatCellsLessThan": "Formate células MENOS QUE:",
        "FormatCellsBetween": "Formate células que estão ENTRE:",
        "FormatCellsEqualTo": "Formate células IGUAIS A:",
        "FormatCellsThatContainTheText": "Formate as células que contêm o texto:",
        "FormatCellsThatContainADateOccurring": "Formate as células que contêm uma data que ocorre:",
        "FormatCellsDuplicate": "Formate células que contenham:",
        "FormatCellsTop": "Formate as células que classificam no TOP:",
        "FormatCellsBottom": "Formate as células que se classificam no BOTTOM:",
        "FormatCellsAbove": "Formate as células que estão ACIMA DA MÉDIA:",
        "FormatCellsBelow": "Formate as células ABAIXO DA MÉDIA:",
        "With": "com",
        "DataBars": "Barras de Dados",
        "ColorScales": "Escalas de Cores",
        "IconSets": "Conjuntos de ícones",
        "ClearRules": "ClearRules",
        "SelectedCells": "Limpar regras das células selecionadas",
        "EntireSheet": "Limpar regras de toda a planilha",
        "LightRedFillWithDarkRedText": "Preenchimento Vermelho Claro com Texto Vermelho Escuro",
        "YellowFillWithDarkYellowText": "Preenchimento amarelo com texto amarelo escuro",
        "GreenFillWithDarkGreenText": "Preenchimento Verde com Texto Verde Escuro",
        "RedFill": "Preenchimento Vermelho",
        "RedText": "Texto Vermelho",
        "Duplicate": "Duplicado",
        "Unique": "Único",
        "And": "e",
        "WebPage": "PÁGINA DA WEB",
        "ThisDocument": "ESSE DOCUMENTO",
        "DisplayText": "Texto de exibição",
        "Url": "URL",
        "CellReference": "Referência de Célula",
        "DefinedNames": "Nomes Definidos",
        "EnterTheTextToDisplay": "Insira o texto a ser exibido",
        "EnterTheUrl": "Insira o URL",
        "INT": "Retorna um número para o inteiro mais próximo.",
        "SUMPRODUCT": "Retorna a soma do produto de determinados intervalos de matrizes.",
        "TODAY": "Retorna a data atual como valor de data.",
        "ROUNDUP": "Arredonda um número longe de zero.",
        "Replace": "Substituir...",
        "Find": "Encontrar e substituir ...",
        "Goto": "Vamos para...",
        "GotoSpecial": "GotoSpecial ...",
        "Link": "Ligação",
        "SpreadsheetHyperlink": "SpreadsheetHyperlink",
        "Hyperlink": "Hiperlink",
        "EditHyperlink": "Editar hiperlink",
        "OpenHyperlink": "Abrir hiperlink",
        "RemoveHyperlink": "Remover hiperlink",
        "InvalidHyperlinkAlert": "O endereço deste site não é válido. Verifique o endereço e tente novamente.",
        "InsertLink": "Inserir Link",
        "EditLink": "Editar Link",
        "WrapText": "Quebrar Texto",
        "Update": "Atualizar",
        "SortAndFilter": "Classificar e filtrar",
        "Filter": "Filtro",
        "FilterCellValue": "Filtrar por valor da célula selecionada",
        "FilterOutOfRangeError": "Selecione uma célula ou intervalo dentro do intervalo usado e tente novamente.",
        "ClearFilterFrom": "Limpar filtro de",
        "LN": "Retorna o logaritmo natural de um número.",
        "DefineNameInValid": "O nome que você digitou não é válido.",
        "LockCells": "Células de bloqueio",
        "EmptyError": "Você deve inserir um valor",
        "ClearHighlight": "Limpar destaque",
        "HighlightInvalidData": "Destacar dados inválidos",
        "Clear": "Limpar",
        "ClearContents": "Conteúdo limpo",
        "ClearAll": "Limpar tudo",
        "ClearFormats": "Limpar Formatos",
        "ClearHyperlinks": "Limpar hiperlinks",
        "Image": "Imagem",
        "ConditionalFormatting": "Formatação condicional",
        "BlueDataBar": "Barra de Dados Azul",
        "GreenDataBar": "Barra de Dados Verde",
        "RedDataBar": "Barra de Dados Vermelha",
        "OrangeDataBar": "Barra de Dados Laranja",
        "LightblueDataBar": "Barra de dados azul claro",
        "PurpleDataBar": "Barra de Dados Roxa",
        "GYRColorScale": "Escala de cor verde - amarelo - vermelho",
        "RYGColorScale": "Escala de cores vermelho - amarelo - verde",
        "GWRColorScale": "Escala de cor verde - branco - vermelho",
        "RWGColorScale": "Escala de cores vermelho - branco - verde",
        "BWRColorScale": "Escala de cor azul - branco - vermelho",
        "RWBColorScale": "Escala de cores vermelho - branco - azul",
        "WRColorScale": "Branco - Escala de Cor Vermelha",
        "RWColorScale": "Vermelho - Escala de cor branca",
        "GWColorScale": "Escala de cor verde - branco",
        "WGColorScale": "Branco - Escala de Cor Verde",
        "GYColorScale": "Escala de cor verde - amarela",
        "YGColorScale": "Escala de cor amarela - verde",
        "ThreeArrowsColor": "3 setas (coloridas)",
        "ThreeArrowsGray": "3 setas (cinza)",
        "ThreeTriangles": "3 triângulos",
        "FourArrowsColor": "4 setas (cinza)",
        "FourArrowsGray": "4 setas (coloridas)",
        "FiveArrowsColor": "5 setas (cinza)",
        "FiveArrowsGray": "5 setas (coloridas)",
        "ThreeTrafficLights1": "3 semáforos (sem moldura)",
        "ThreeTrafficLights2": "3 semáforos (orlados)",
        "ThreeSigns": "3 sinais",
        "FourTrafficLights": "4 semáforos",
        "RedToBlack": "Vermelho para Preto",
        "ThreeSymbols1": "3 símbolos (circulados)",
        "ThreeSymbols2": "3 símbolos (sem círculo)",
        "ThreeFlags": "3 bandeiras",
        "ThreeStars": "3 estrelas",
        "FourRatings": "4 avaliações",
        "FiveQuarters": "5 quartos",
        "FiveRatings": "5 avaliações",
        "FiveBoxes": "5 caixas",
        "Chart": "Gráfico",
        "Column": "Coluna",
        "Bar": "Barra",
        "Area": "Área",
        "Pie": "Torta",
        "Doughnut": "Rosquinha",
        "PieAndDoughnut": "Torta / Donut",
        "Line": "Linha",
        "Radar": "Radar",
        "Scatter": "Dispersão",
        "ChartDesign": "Design Gráfico",
        "ClusteredColumn": "Coluna Agrupada",
        "StackedColumn": "Coluna Empilhada",
        "StackedColumn100": "Coluna 100% Empilhada",
        "ClusteredBar": "Barra Agrupada",
        "StackedBar": "Barra Empilhada",
        "StackedBar100": "Barra 100% Empilhada",
        "StackedArea": "Área Empilhada",
        "StackedArea100": "100% de área empilhada",
        "StackedLine": "Linha Empilhada",
        "StackedLine100": "Linha 100% Empilhada",
        "AddChartElement": "Adicionar elemento gráfico",
        "Axes": "Machados",
        "AxisTitle": "Título do Eixo",
        "ChartTitle": "Título do gráfico",
        "DataLabels": "Rótulos de dados",
        "Gridlines": "Gridlines",
        "Legends": "Legendas",
        "PrimaryHorizontal": "Horizontal Primário",
        "PrimaryVertical": "Vertical Primário",
        "None": "Nenhum",
        "AboveChart": "Gráfico Acima",
        "Center": "Centro",
        "InsideEnd": "Fim Interno",
        "InsideBase": "Base Interna",
        "OutsideEnd": "OutSide End",
        "PrimaryMajorHorizontal": "Horizontal Principal Principal",
        "PrimaryMajorVertical": "Vertical Principal Principal",
        "PrimaryMinorHorizontal": "Primário menor horizontal",
        "PrimaryMinorVertical": "Vertical secundária primária",
        "Right": "Direito",
        "Left": "Deixou",
        "Bottom": "Inferior",
        "Top": "Principal",
        "SwitchRowColumn": "Mudar de linha / coluna",
        "ChartTheme": "Tema gráfico",
        "ChartType": "Tipo de Gráfico",
        "Material": "Material",
        "Fabric": "Tecido",
        "Bootstrap": "Bootstrap",
        "HighContrastLight": "HighContrastLight",
        "MaterialDark": "MaterialDark",
        "FabricDark": "FabricDark",
        "HighContrast": "HighContrast",
        "BootstrapDark": "BootstrapDark",
        "Bootstrap4": "Bootstrap4",
        "VerticalAxisTitle": "Título do eixo vertical",
        "HorizontalAxisTitle": "Título do eixo horizontal",
        "EnterTitle": "Digite o título",
        "ProtectWorkbook": "Proteger a pasta de trabalho",
        "Password": "Senha (opcional):",
        "unProtectPassword": "Senha",
        "EnterThePassword": "Digite a senha",
        "ConfirmPassword": "Confirme a Senha",
        "EnterTheConfirmPassword": "Redigite sua senha",
        "PasswordAlert": "A senha de confirmação não é idêntica",
        "UnProtectWorkbook": "Desproteger a pasta de trabalho",
        "UnProtectPasswordAlert": "A senha que você forneceu não está correta.",
        "InCorrectPassword": "Não é possível abrir o arquivo ou planilha com a senha fornecida",
        "PasswordAlertMsg": "Por favor insira a senha",
        "ConfirmPasswordAlertMsg": "Por favor digite a senha de confirmação",
        "IsProtected": "está protegido",
        "PDF": "Formato de Documento Portátil",
        "SelectBorderOption": "Selecionar opção de borda"
    },
    "pdfviewer": {
        "PdfViewer": "﻿Visualizador de PDF",
        "Cancel": "Cancelar",
        "Download file": "⇬ Fazer download do arquivo",
        "Download": "Baixar",
        "Enter Password": "Este documento é protegido por senha. Por favor insira uma senha.",
        "File Corrupted": "Arquivo corrompido",
        "File Corrupted Content": "O arquivo está corrompido e não pode ser aberto.",
        "Fit Page": "Ajustar página",
        "Fit Width": "Largura de ajuste",
        "Automatic": "Automático",
        "Go To First Page": "Mostrar primeira página",
        "Invalid Password": "Senha incorreta. Por favor, tente novamente.",
        "Next Page": "Mostrar página seguinte",
        "OK": "Está bem",
        "Open": "Abrir arquivo",
        "Page Number": "Número da página atual",
        "Previous Page": "Mostrar página anterior",
        "Go To Last Page": "Mostrar a última página",
        "Zoom": "Ampliação",
        "Zoom In": "Mais Zoom",
        "Zoom Out": "Reduzir o zoom",
        "Page Thumbnails": "Miniaturas de página",
        "Bookmarks": "Favoritos",
        "Print": "Imprimir arquivo",
        "Password Protected": "Senha requerida",
        "Copy": "cópia de",
        "Text Selection": "Ferramenta de seleção de texto",
        "Panning": "Modo panorâmico",
        "Text Search": "Localizar texto",
        "Find in document": "Localizar no documento",
        "Match case": "Caso de compatibilidade",
        "Apply": "Aplique",
        "GoToPage": "Vá para página",
        "No matches": "O visualizador terminou de pesquisar o documento. Não foram encontradas mais correspondências",
        "No Text Found": "Nenhum texto encontrado",
        "Undo": "Desfazer",
        "Redo": "Refazer",
        "Annotation": "Adicionar ou editar anotações",
        "Highlight": "Realçar texto",
        "Underline": "Sublinhar texto",
        "Strikethrough": "Texto tachado",
        "Delete": "Excluir anotação",
        "Opacity": "Opacidade",
        "Color edit": "Mudar cor",
        "Opacity edit": "Alterar opacidade",
        "Highlight context": "Realçar",
        "Underline context": "Sublinhado",
        "Strikethrough context": "Strike through",
        "Server error": "O serviço da Web não está escutando. O PDF Viewer depende do serviço da Web para todos os seus recursos. Por favor, inicie o serviço web para continuar.",
        "Open text": "Aberto",
        "First text": "Primeira página",
        "Previous text": "Página anterior",
        "Next text": "Próxima página",
        "Last text": "Última página",
        "Zoom in text": "Mais Zoom",
        "Zoom out text": "Reduzir o zoom",
        "Selection text": "Seleção",
        "Pan text": "Pan",
        "Print text": "Impressão",
        "Search text": "Procurar",
        "Annotation Edit text": "Editar anotação",
        "Line Thickness": "Espessura da linha",
        "Line Properties": "Propriedades da linha",
        "Start Arrow": "Seta Inicial",
        "End Arrow": "Seta final",
        "Line Style": "Estilo da linha",
        "Fill Color": "Cor de preenchimento",
        "Line Color": "Cor da linha",
        "None": "Nenhum",
        "Open Arrow": "Aberto",
        "Closed Arrow": "Fechadas",
        "Round Arrow": "Volta",
        "Square Arrow": "Quadrado",
        "Diamond Arrow": "Diamante",
        "Cut": "Cortar",
        "Paste": "Colar",
        "Delete Context": "Excluir",
        "Properties": "Propriedades",
        "Add Stamp": "Adicionar carimbo",
        "Add Shapes": "Adicionar formas",
        "Stroke edit": "Alterar cor do traçado",
        "Change thickness": "Alterar espessura da borda",
        "Add line": "Adicionar linha",
        "Add arrow": "Adicionar seta",
        "Add rectangle": "Adicionar retângulo",
        "Add circle": "Adicionar círculo",
        "Add polygon": "Adicionar polígono",
        "Add Comments": "Adicione comentários",
        "Comments": "Comentários",
        "No Comments Yet": "Ainda não há comentários",
        "Accepted": "Aceitaram",
        "Completed": "Concluído",
        "Cancelled": "Cancelado",
        "Rejected": "Rejeitado",
        "Leader Length": "Comprimento do Líder",
        "Scale Ratio": "Relação de escala",
        "Calibrate": "Calibrar",
        "Calibrate Distance": "Calibrar Distância",
        "Calibrate Perimeter": "Calibrar perímetro",
        "Calibrate Area": "Calibrar área",
        "Calibrate Radius": "Calibrar raio",
        "Calibrate Volume": "Calibrar volume"
    },
    "querybuilder": {
        "StartsWith": "Começa com",
        "EndsWith": "Termina com",
        "Contains": "Contém",
        "Equal": "Igual",
        "NotEqual": "Não igual",
        "LessThan": "Menor que",
        "LessThanOrEqual": "Menor ou igual",
        "GreaterThan": "Maior que",
        "GreaterThanOrEqual": "Maior que ou igual",
        "Between": "Entre",
        "NotBetween": "Não entre",
        "Empty": "Esvaziar",
        "NotEmpty": "Não está vazio",
        "In": "Dentro",
        "NotIn": "Não em",
        "NotContains": "Não contém",
        "Remove": "RETIRAR",
        "SelectField": "Selecione um campo",
        "SelectOperator": "Selecionar operador",
        "DeleteRule": "Remova esta condição",
        "DeleteGroup": "Excluir grupo",
        "AddGroup": "Adicionar grupo",
        "AddCondition": "Adicionar condição",
        "Edit": "EDITAR",
        "ValidationMessage": "Este campo é obrigatório",
        "SummaryViewTitle": "Vista de Resumo",
        "OtherFields": "Outros campos",
        "AND": "E",
        "OR": "OU",
        "SelectValue": "Digite o valor",
        "IsEmpty": "Está vazia",
        "IsNotEmpty": "Não está vazio",
        "IsNull": "É nulo",
        "IsNotNull": "Não é nulo",
        "True": "verdadeiro",
        "False": "falso"
    },
    "grid": {
        "EmptyRecord": "Não há registros a serem exibidos",
        "True": "verdade",
        "False": "falso",
        "InvalidFilterMessage": "Dados de filtro inválidos",
        "GroupDropArea": "Arraste um cabeçalho de coluna aqui para agrupar sua coluna",
        "UnGroup": "Clique aqui para desagrupar",
        "GroupDisable": "O agrupamento está desativado para esta coluna",
        "FilterbarTitle": "célula da barra de filtro",
        "EmptyDataSourceError": "O DataSource não deve estar vazio no carregamento inicial, pois as colunas são geradas a partir do dataSource no AutoGenerate Column Grid",
        "Add": "Adicionar",
        "Edit": "Editar",
        "Cancel": "Cancelar",
        "Update": "Atualizar",
        "Delete": "Excluir",
        "Print": "Impressão",
        "Pdfexport": "Exportação de PDF",
        "Excelexport": "Exportação Excel",
        "Wordexport": "Exportação de palavras",
        "Csvexport": "Exportação de CSV",
        "Search": "Procurar",
        "Columnchooser": "Colunas",
        "Save": "Salve ",
        "Item": "item",
        "Items": "Itens",
        "EditOperationAlert": "Nenhum registro selecionado para operação de edição",
        "DeleteOperationAlert": "Nenhum registro selecionado para operação de exclusão",
        "SaveButton": "Salve ",
        "OKButton": "Está bem",
        "CancelButton": "Cancelar",
        "EditFormTitle": "Detalhes de",
        "AddFormTitle": "Adicionar novo registro",
        "BatchSaveConfirm": "Tem certeza de que deseja salvar as alterações?",
        "BatchSaveLostChanges": "Alterações não salvas serão perdidas. Você tem certeza que quer continuar?",
        "ConfirmDelete": "Tem certeza de que deseja excluir o registro?",
        "CancelEdit": "Tem certeza de que deseja cancelar as alterações?",
        "ChooseColumns": "Escolher coluna",
        "SearchColumns": "colunas de pesquisa",
        "Matchs": "Nenhuma equivalência encontrada",
        "FilterButton": "Filtro",
        "ClearButton": "Limpar",
        "StartsWith": "Começa com",
        "EndsWith": "Termina com",
        "Contains": "Contém",
        "Equal": "Igual",
        "NotEqual": "Não igual",
        "LessThan": "Menor que",
        "LessThanOrEqual": "Menor ou igual",
        "GreaterThan": "Maior que",
        "GreaterThanOrEqual": "Maior que ou igual",
        "ChooseDate": "Escolha uma data",
        "EnterValue": "Digite o valor",
        "Copy": "cópia de",
        "Group": "Agrupar por esta coluna",
        "Ungroup": "Desagrupar por esta coluna",
        "autoFitAll": "Ajustar automaticamente a todas as colunas",
        "autoFit": "Ajustar automaticamente a esta coluna",
        "Export": "Exportação",
        "FirstPage": "Primeira página",
        "LastPage": "Última página",
        "PreviousPage": "Página anterior",
        "NextPage": "Próxima página",
        "SortAscending": "Ordernar ascendente",
        "SortDescending": "Classificar em ordem decrescente",
        "EditRecord": "Editar registro",
        "DeleteRecord": "Apagar registro",
        "FilterMenu": "Filtro",
        "SelectAll": "Selecionar tudo",
        "Blanks": "Espaços em branco",
        "FilterTrue": "Verdade",
        "FilterFalse": "Falso",
        "NoResult": "Nenhuma equivalência encontrada",
        "ClearFilter": "Filtro limpo",
        "NumberFilter": "Filtros numéricos",
        "TextFilter": "Filtros de texto",
        "DateFilter": "Filtros de data",
        "DateTimeFilter": "Filtros DateTime",
        "MatchCase": "Caso de compatibilidade",
        "Between": "Entre",
        "CustomFilter": "Filtro customizado",
        "CustomFilterPlaceHolder": "Digite o valor",
        "CustomFilterDatePlaceHolder": "Escolha uma data",
        "AND": "E",
        "OR": "OU",
        "ShowRowsWhere": "Mostrar linhas onde:"
    },
    "pager": {
        "currentPageInfo": "{0} de {1} páginas",
        "totalItemsInfo": "({0} itens)",
        "firstPageTooltip": "Ir para a primeira página",
        "lastPageTooltip": "Ir para a última página",
        "nextPageTooltip": "Ir para a próxima página",
        "previousPageTooltip": "Ir para a página anterior",
        "nextPagerTooltip": "Ir para o próximo pager",
        "previousPagerTooltip": "Ir para o pager anterior",
        "pagerDropDown": "itens por página",
        "pagerAllDropDown": "Itens",
        "All": "Todos",
        "totalItemInfo": "({0} item)"
    },
    "calendar": {
        "today": "Hoje"
    },
    "datepicker": {
        "today": "Hoje",
        "placeholder": "Escolha uma data"
    },
    "daterangepicker": {
        "placeholder": "Escolha um período",
        "startLabel": "Data de início",
        "endLabel": "Data final",
        "applyText": "Aplique",
        "cancelText": "Cancelar",
        "selectedDays": "Dias Selecionados",
        "days": "Dias",
        "customRange": "Gama personalizada"
    },
    "timepicker": {
        "placeholder": "Escolha um horário"
    },
    "datetimepicker": {
        "today": "Hoje",
        "placeholder": "Escolha uma data e hora"
    },
    "gantt": {
        "emptyRecord": "Não há registros a serem exibidos",
        "id": "EU IRIA",
        "name": "Nome",
        "startDate": "Data de início",
        "endDate": "Data final",
        "duration": "Duração",
        "progress": "Progresso",
        "dependency": "Dependência",
        "notes": "Notas",
        "baselineStartDate": "Data de início da linha de base",
        "baselineEndDate": "Data de término da linha de base",
        "taskMode": "Modo Tarefa",
        "changeScheduleMode": "Alterar modo de programação",
        "subTasksStartDate": "Data de início das subtarefas",
        "subTasksEndDate": "Data de término das subtarefas",
        "scheduleStartDate": "Data de início da programação",
        "scheduleEndDate": "Data de término da programação",
        "auto": "Auto",
        "manual": "Manual",
        "type": "Tipo",
        "offset": "Deslocamento",
        "resourceName": "Recursos",
        "resourceID": "ID do Recurso",
        "day": "dia",
        "hour": "hora",
        "minute": "minuto",
        "days": "dias",
        "hours": "horas",
        "minutes": "minutos",
        "generalTab": "Geral",
        "customTab": "Colunas personalizadas",
        "writeNotes": "Escrever notas",
        "addDialogTitle": "Nova tarefa",
        "editDialogTitle": "Informações da tarefa",
        "saveButton": "Salve ",
        "add": "Adicionar",
        "edit": "Editar",
        "update": "Atualizar",
        "delete": "Excluir",
        "cancel": "Cancelar",
        "search": "Procurar",
        "task": " tarefa",
        "tasks": " tarefas",
        "zoomIn": "Mais Zoom",
        "zoomOut": "Reduzir o zoom",
        "zoomToFit": "Zoom para ajustar",
        "excelExport": "Exportação Excel",
        "csvExport": "Exportação de CSV",
        "expandAll": "Expandir todos",
        "collapseAll": "Recolher todos",
        "nextTimeSpan": "Próximo período",
        "prevTimeSpan": "Período anterior",
        "okText": "Está bem",
        "confirmDelete": "Tem certeza de que deseja excluir o registro?",
        "from": "A partir de",
        "to": "Para",
        "taskLink": "Link de Tarefa",
        "lag": "Lag",
        "start": "Começar",
        "finish": "Terminar",
        "enterValue": "Digite o valor",
        "taskBeforePredecessor_FS": "Você moveu '{0}' para iniciar antes que '{1}' termine e as duas tarefas estejam vinculadas. Como resultado, os links não podem ser respeitados. Selecione uma ação abaixo para executar",
        "taskAfterPredecessor_FS": "Você mudou '{0}' de '{1}' e as duas tarefas estão vinculadas. Como resultado, os links não podem ser respeitados. Selecione uma ação abaixo para executar",
        "taskBeforePredecessor_SS": "Você moveu '{0}' para iniciar antes de '{1}' iniciar e as duas tarefas estão vinculadas. Como resultado, os links não podem ser respeitados. Selecione uma ação abaixo para executar",
        "taskAfterPredecessor_SS": "Você moveu '{0}' para iniciar após o início de '{1}' e as duas tarefas estão vinculadas. Como resultado, os links não podem ser respeitados. Selecione uma ação abaixo para executar",
        "taskBeforePredecessor_FF": "Você moveu '{0}' para concluir antes que '{1}' termine e as duas tarefas estejam vinculadas. Como resultado, os links não podem ser respeitados. Selecione uma ação abaixo para executar",
        "taskAfterPredecessor_FF": "Você moveu '{0}' para concluir depois que '{1}' terminar e as duas tarefas estiverem vinculadas. Como resultado, os links não podem ser respeitados. Selecione uma ação abaixo para executar",
        "taskBeforePredecessor_SF": "Você mudou '{0}' de '{1}' para iniciar e as duas tarefas estão vinculadas. Como resultado, os links não podem ser respeitados. Selecione uma ação abaixo para executar",
        "taskAfterPredecessor_SF": "Você moveu '{0}' para concluir após o início de '{1}' e as duas tarefas estão vinculadas. Como resultado, os links não podem ser respeitados. Selecione uma ação abaixo para executar",
        "taskInformation": "Informações da tarefa",
        "deleteTask": "Excluir tarefa",
        "deleteDependency": "Excluir dependência",
        "convert": "Converter",
        "save": "Salve ",
        "above": "Acima",
        "below": "Abaixo",
        "child": "Criança",
        "milestone": "Marco histórico",
        "toTask": "Tarefa",
        "toMilestone": "Para Marco",
        "eventMarkers": "Marcadores de evento",
        "leftTaskLabel": "Rótulo de tarefa esquerdo",
        "rightTaskLabel": "Rótulo de tarefa correto",
        "timelineCell": "Célula da linha do tempo",
        "confirmPredecessorDelete": "Tem certeza de que deseja remover o link de dependência?",
        "unit": "Unidade",
        "work": "Trabalhos",
        "taskType": "Tipo de tarefa",
        "unassignedTask": "Tarefa não atribuída",
        "group": "Grupo",
        "indent": "Recuar",
        "outdent": "Outdent",
        "segments": "Segmentos",
        "splitTask": "Tarefa Dividida",
        "mergeTask": "Mesclar Tarefa",
        "left": "Esquerda",
        "right": "Direito"
    },
    "dropdowns": {
        "noRecordsTemplate": "Nenhum registro foi encontrado",
        "actionFailureTemplate": "A solicitação falhou",
        "overflowCountTemplate": "+${count} mais ..",
        "selectAllText": "Selecionar tudo",
        "unSelectAllText": "Desmarque todos",
        "totalCountTemplate": "${count} selecionado"
    },
    "drop-down-list": {
        "noRecordsTemplate": "Nenhum registro foi encontrado",
        "actionFailureTemplate": "A solicitação falhou"
    },
    "combo-box": {
        "noRecordsTemplate": "Nenhum registro foi encontrado",
        "actionFailureTemplate": "A solicitação falhou"
    },
    "auto-complete": {
        "noRecordsTemplate": "Nenhum registro foi encontrado",
        "actionFailureTemplate": "A solicitação falhou"
    },
    "multi-select": {
        "noRecordsTemplate": "Nenhum registro foi encontrado",
        "actionFailureTemplate": "A solicitação falhou",
        "overflowCountTemplate": "+${count} mais ..",
        "selectAllText": "Selecionar tudo",
        "unSelectAllText": "Desmarque todos",
        "totalCountTemplate": "${count} selecionado"
    },
    "listbox": {
        "noRecordsTemplate": "Nenhum registro foi encontrado",
        "actionFailureTemplate": "A solicitação falhou",
        "selectAllText": "Selecionar tudo",
        "unSelectAllText": "Desmarque todos",
        "moveUp": "Subir",
        "moveDown": "Mover para baixo",
        "moveTo": "Mover para",
        "moveFrom": "Mover-se a partir de",
        "moveAllTo": "Mover tudo para",
        "moveAllFrom": "Mover tudo de"
    },
    "dialog": {
        "close": "Fechar"
    },
    "rich-text-editor": {
        "alignments": "alinhamentos",
        "justifyleft": "justifyLeft",
        "justifycenter": "justifyCenter",
        "justifyright": "justifyRight",
        "justifyfull": "justifyFull",
        "fontname": "fontName",
        "fontsize": "tamanho da fonte",
        "fontcolor": "cor da fonte",
        "backgroundcolor": "cor de fundo",
        "clearformat": "clearFormat",
        "clearall": "limpar tudo",
        "unorderedlist": "unorderedList",
        "orderedlist": "ordersList",
        "createlink": "createLink",
        "openlink": "link aberto",
        "editlink": "editLink",
        "removelink": "removeLink",
        "openimagelink": "link aberto",
        "editimagelink": "editLink",
        "removeimagelink": "removeLink",
        "image": "imagem",
        "replace": "substituir",
        "align": "alinhar",
        "caption": "rubrica",
        "remove": "retirar",
        "insertlink": "insertLink",
        "display": "exibição",
        "alttext": "texto alternativo",
        "dimension": "dimensão",
        "fullscreen": "tela cheia",
        "maximize": "maximizar",
        "minimize": "minimizar",
        "lowercase": "lowerCase",
        "uppercase": "upperCase",
        "print": "impressão",
        "formats": "formatos",
        "sourcecode": "Código fonte",
        "preview": "pré-visualização",
        "viewside": "vista",
        "insertcode": "insira Código",
        "justifyLeft": "Alinhar à esquerda",
        "justifyCenter": "Alinhar ao centro",
        "justifyRight": "Alinhar à direita",
        "justifyFull": "Alinhar justificar",
        "fontName": "Nome da fonte",
        "fontSize": "Tamanho da fonte",
        "fontColor": "Cor da fonte",
        "backgroundColor": "Cor de fundo",
        "bold": "negrito",
        "italic": "itálico",
        "underline": "sublinhado",
        "strikethrough": "tachado",
        "cut": "cortar",
        "copy": "cópia de",
        "paste": "colar",
        "indent": "recuar",
        "outdent": "superar",
        "undo": "desfazer",
        "redo": "refazer",
        "superscript": "sobrescrito",
        "subscript": "subscrito",
        "createLink": "Insira o hyperlink",
        "openLink": "Link aberto",
        "editLink": "Editar Link",
        "removeLink": "Remover link",
        "insertLink": "Inserir link",
        "altText": "Texto Alternativo",
        "lowerCase": "Minúsculas",
        "upperCase": "Maiúsculas",
        "insertCode": "Insira Código",
        "linkText": "Texto de exibição",
        "linkTooltipLabel": "Sugestão",
        "linkWebUrl": "Endereço da Web",
        "linkurl": "http://example.com",
        "linkOpenInNewWindow": "Abrir link em nova janela",
        "linkHeader": "Inserir link",
        "dialogInsert": "Inserir",
        "dialogCancel": "Cancelar",
        "dialogUpdate": "Atualizar",
        "imageHeader": "Inserir Imagem",
        "imageLinkHeader": "Você também pode fornecer um link da web",
        "mdimageLink": "Forneça um URL para sua imagem",
        "imageUploadMessage": "Solte a imagem aqui ou navegue para fazer upload",
        "imageDeviceUploadMessage": "Clique aqui para fazer o upload",
        "imageAlternateText": "Texto alternativo",
        "alternateHeader": "Texto Alternativo",
        "browse": "Squeaky toy",
        "imageUrl": "http://example.com/image.png",
        "imageCaption": "Rubrica",
        "imageSizeHeader": "Tamanho da imagem",
        "imageHeight": "Altura",
        "imageWidth": "Largura",
        "textPlaceholder": "Inserir texto"
    },
    "inplace-editor": {
        "editIcon": "Clique para editar",
        "save": "Salve ",
        "cancel": "Cancelar"
    },
    "chart": {
        "Zoom": "Ampliação",
        "ZoomIn": "Mais Zoom",
        "ZoomOut": "Reduzir o zoom",
        "Reset": "Redefinir",
        "Pan": "Pan",
        "ResetZoom": "Redefinir zoom"
    },
    "drop-down-base": {
        "noRecordsTemplate": "Nenhum registro foi encontrado",
        "actionFailureTemplate": "A solicitação falhou"
    },
    "maps": {
        "Zoom": "Ampliação",
        "ZoomIn": "Mais Zoom",
        "ZoomOut": "Reduzir o zoom",
        "Reset": "Redefinir",
        "Pan": "Pan",
        "ResetZoom": "Redefinir zoom"
    },
    "PdfViewer": {
        "PdfViewer": "Visualizador de PDF",
        "Cancel": "Cancelar",
        "Download file": "⇬ Fazer download do arquivo",
        "Download": "Baixar",
        "Enter Password": "Este documento é protegido por senha. Por favor insira uma senha.",
        "File Corrupted": "Arquivo corrompido",
        "File Corrupted Content": "O arquivo está corrompido e não pode ser aberto.",
        "Fit Page": "Ajustar página",
        "Fit Width": "Largura de ajuste",
        "Automatic": "Automático",
        "Go To First Page": "Mostrar primeira página",
        "Invalid Password": "Senha incorreta. Por favor, tente novamente.",
        "Next Page": "Mostrar página seguinte",
        "OK": "Está bem",
        "Open": "Abrir arquivo",
        "Page Number": "Número da página atual",
        "Previous Page": "Mostrar página anterior",
        "Go To Last Page": "Mostrar a última página",
        "Zoom": "Ampliação",
        "Zoom In": "Mais Zoom",
        "Zoom Out": "Reduzir o zoom",
        "Page Thumbnails": "Miniaturas de página",
        "Bookmarks": "Favoritos",
        "Print": "Imprimir arquivo",
        "Password Protected": "Senha requerida",
        "Copy": "cópia de",
        "Text Selection": "Ferramenta de seleção de texto",
        "Panning": "Modo panorâmico",
        "Text Search": "Localizar texto",
        "Find in document": "Localizar no documento",
        "Match case": "Caso de compatibilidade",
        "Apply": "Aplique",
        "GoToPage": "Vá para página",
        "No matches": "O visualizador terminou de pesquisar o documento. Não foram encontradas mais correspondências",
        "No Text Found": "Nenhum texto encontrado",
        "Undo": "Desfazer",
        "Redo": "Refazer",
        "Annotation": "Adicionar ou editar anotações",
        "Highlight": "Realçar texto",
        "Underline": "Sublinhar texto",
        "Strikethrough": "Texto tachado",
        "Delete": "Excluir anotação",
        "Opacity": "Opacidade",
        "Color edit": "Mudar cor",
        "Opacity edit": "Alterar opacidade",
        "Highlight context": "Realçar",
        "Underline context": "Sublinhado",
        "Strikethrough context": "Strike through",
        "Server error": "O serviço da Web não está escutando. O Visualizador de PDF depende do serviço da Web para todos os seus recursos. Por favor, inicie o serviço web para continuar.",
        "Open text": "Aberto",
        "First text": "Primeira página",
        "Previous text": "Página anterior",
        "Next text": "Próxima página",
        "Last text": "Última página",
        "Zoom in text": "Mais Zoom",
        "Zoom out text": "Reduzir o zoom",
        "Selection text": "Seleção",
        "Pan text": "Pan",
        "Print text": "Impressão",
        "Search text": "Procurar",
        "Annotation Edit text": "Editar anotação",
        "Line Thickness": "Espessura da linha",
        "Line Properties": "Propriedades da linha",
        "Start Arrow": "Seta Inicial",
        "End Arrow": "Seta final",
        "Line Style": "Estilo da linha",
        "Fill Color": "Cor de preenchimento",
        "Line Color": "Cor da linha",
        "None": "Nenhum",
        "Open Arrow": "Aberto",
        "Closed Arrow": "Fechadas",
        "Round Arrow": "Volta",
        "Square Arrow": "Quadrado",
        "Diamond Arrow": "Diamante",
        "Butt": "Bunda",
        "Cut": "Cortar",
        "Paste": "Colar",
        "Delete Context": "Excluir",
        "Properties": "Propriedades",
        "Add Stamp": "Adicionar carimbo",
        "Add Shapes": "Adicionar formas",
        "Stroke edit": "Alterar cor do traçado",
        "Change thickness": "Alterar espessura da borda",
        "Add line": "Adicionar linha",
        "Add arrow": "Adicionar seta",
        "Add rectangle": "Adicionar retângulo",
        "Add circle": "Adicionar círculo",
        "Add polygon": "Adicionar polígono",
        "Add Comments": "Adicione comentários",
        "Comments": "Comentários",
        "No Comments Yet": "Ainda não há comentários",
        "Accepted": "Aceitaram",
        "Completed": "Concluído",
        "Cancelled": "Cancelado",
        "Rejected": "Rejeitado",
        "Leader Length": "Comprimento do Líder",
        "Scale Ratio": "Relação de escala",
        "Calibrate": "Calibrar",
        "Calibrate Distance": "Calibrar Distância",
        "Calibrate Perimeter": "Calibrar perímetro",
        "Calibrate Area": "Calibrar área",
        "Calibrate Radius": "Calibrar raio",
        "Calibrate Volume": "Calibrar volume",
        "Depth": "Profundidade",
        "Closed": "Fechados",
        "Round": "Redondo",
        "Square": "Praça",
        "Diamond": "Diamante",
        "Edit": "Editar",
        "Comment": "Comente",
        "Comment Panel": "Painel de Comentários",
        "Set Status": "Definir status",
        "Post": "Publicar",
        "Page": "Página",
        "Add a comment": "Adicione um comentário",
        "Add a reply": "Adicione uma resposta",
        "Import Annotations": "Importar anotações do arquivo JSON",
        "Export Annotations": "Exportar anotação para arquivo JSON",
        "Export XFDF": "Exportar anotação para arquivo XFDF",
        "Import XFDF": "Importar anotações de arquivo XFDF",
        "Add": "Adicionar",
        "Clear": "Limpar",
        "Bold": "Negrito",
        "Italic": "Itálico",
        "Strikethroughs": "Tachado",
        "Underlines": "Sublinhado",
        "Superscript": "Sobrescrito",
        "Subscript": "Subscrito",
        "Align left": "Alinhar à esquerda",
        "Align right": "Alinhar à Direita",
        "Center": "Centro",
        "Justify": "Justificar",
        "Font color": "Cor da fonte",
        "Text Align": "Alinhamento de texto",
        "Text Properties": "Estilo de fonte",
        "Draw Signature": "Sorteio de assinatura",
        "Draw Ink": "Desenhar tinta",
        "Create": "Crio",
        "Font family": "Família de fontes",
        "Font size": "Tamanho da fonte",
        "Free Text": "Texto livre",
        "Import Failed": "Tipo de arquivo JSON ou nome de arquivo inválido; selecione um arquivo JSON válido",
        "File not found": "O arquivo JSON importado não foi encontrado no local desejado",
        "Export Failed": "A ação de exportação de anotações falhou; certifique-se de que as anotações sejam adicionadas corretamente",
        "of": "de",
        "Dynamic": "Dinâmico",
        "Standard Business": "Negócios Padrão",
        "Sign Here": "Assine aqui",
        "Custom Stamp": "Carimbo Personalizado",
        "Enter Signature as Name": "Digite seu nome",
        "Draw-hand Signature": "EMPATE",
        "Type Signature": "TIPO",
        "Upload Signature": "ENVIO",
        "Browse Signature Image": "SQUEAKY TOY",
        "Save Signature": "Salvar assinatura"
    },
    "documenteditor": {
        "Table": "Mesa",
        "Row": "Linha",
        "Cell": "Célula",
        "Ok": "Está bem",
        "Cancel": "Cancelar",
        "Size": "Tamanho",
        "Preferred Width": "Largura preferida",
        "Points": "Pontos",
        "Percent": "Por cento",
        "Measure in": "Medida em",
        "Alignment": "Alinhamento",
        "Left": "Esquerda",
        "Center": "Centro",
        "Right": "Certo",
        "Justify": "Justificar",
        "Indent from left": "Recuar da esquerda",
        "Borders and Shading": "Bordas e sombreamento",
        "Options": "Opções",
        "Specify height": "Especifique a altura",
        "At least": "Finalmente",
        "Exactly": "Exatamente",
        "Row height is": "A altura da linha é",
        "Allow row to break across pages": "Permitir quebra de linha nas páginas",
        "Repeat as header row at the top of each page": "Repita como linha de cabeçalho na parte superior de cada página",
        "Vertical alignment": "Alinhamento vertical",
        "Top": "Topo",
        "Bottom": "Inferior",
        "Default cell margins": "Margens de célula padrão",
        "Default cell spacing": "Espaçamento de célula padrão",
        "Allow spacing between cells": "Permitir espaçamento entre células",
        "Cell margins": "Margens da célula",
        "Same as the whole table": "Igual à tabela inteira",
        "Borders": "Fronteiras",
        "None": "Nenhum",
        "Style": "Estilo",
        "Width": "Largura",
        "Height": "Altura",
        "Letter": "Carta",
        "Tabloid": "Tablóide",
        "Legal": "Legal",
        "Statement": "Declaração",
        "Executive": "Executivo",
        "A3": "A3",
        "A4": "A4",
        "A5": "A5",
        "B4": "B4",
        "B5": "B5",
        "Custom Size": "Tamanho personalizado",
        "Different odd and even": "Diferente e ímpar",
        "Different first page": "Primeira página diferente",
        "From edge": "De borda",
        "Header": "Cabeçalho",
        "Footer": "Rodapé",
        "Margin": "Margens",
        "Paper": "Papel",
        "Layout": "Layout",
        "Orientation": "Orientação",
        "Landscape": "Panorama",
        "Portrait": "Retrato",
        "Show page numbers": "Mostrar números de página",
        "Right align page numbers": "Alinhar à direita os números das páginas",
        "Nothing": "Nada",
        "Tab leader": "Líder da guia",
        "Show levels": "Mostrar níveis",
        "Use hyperlinks instead of page numbers": "Use hiperlinks em vez de números de página",
        "Build table of contents from": "Crie um índice a partir de",
        "Styles": "Estilos",
        "Available styles": "Estilos disponíveis",
        "TOC level": "Nível TOC",
        "Heading": "Título",
        "Heading 1": "Cabeçallho 1",
        "Heading 2": "Rubrica 2",
        "Heading 3": "Rubrica 3",
        "Heading 4": "Rubrica 4",
        "Heading 5": "Rubrica 5",
        "Heading 6": "Rubrica 6",
        "List Paragraph": "Parágrafo da lista",
        "Normal": "Normal",
        "Outline levels": "Níveis de estrutura de tópicos",
        "Table entry fields": "Campos de entrada da tabela",
        "Modify": "Modificar",
        "Color": "Cor",
        "Setting": "Configuração",
        "Box": "Caixa",
        "All": "Todos",
        "Custom": "personalizadas",
        "Preview": "Pré-visualização",
        "Shading": "Sombreamento",
        "Fill": "Preencher",
        "Apply To": "Aplicar a",
        "Table Properties": "Propriedades da tabela",
        "Cell Options": "Opções de célula",
        "Table Options": "Opções de tabela",
        "Insert Table": "Insira a tabela",
        "Number of columns": "Numero de colunas",
        "Number of rows": "Numero de linhas",
        "Text to display": "Texto a ser exibido",
        "Address": "Endereço",
        "Insert Hyperlink": "Insira o hyperlink",
        "Edit Hyperlink": "Editar hiperlink",
        "Insert": "Inserir",
        "General": "Geral",
        "Indentation": "Indentação",
        "Before text": "Antes do texto",
        "Special": "Especial",
        "First line": "Primeira linha",
        "Hanging": "Suspensão",
        "After text": "Após o texto",
        "By": "Por",
        "Before": "Antes",
        "Line Spacing": "Espaçamento entre linhas",
        "After": "Depois de",
        "At": "At",
        "Multiple": "Múltiplo",
        "Spacing": "Espaçamento",
        "Define new Multilevel list": "Definir nova lista multinível",
        "List level": "Nível da lista",
        "Choose level to modify": "Escolha o nível para modificar",
        "Level": "Nível",
        "Number format": "Formato numérico",
        "Number style for this level": "Estilo numérico para este nível",
        "Enter formatting for number": "Digite a formatação para o número",
        "Start at": "Começa ás",
        "Restart list after": "Reinicie a lista depois",
        "Position": "Posição",
        "Text indent at": "Recuo do texto em",
        "Aligned at": "Alinhado em",
        "Follow number with": "Siga o número com",
        "Tab character": "Caractere de tabulação",
        "Space": "Espaço",
        "Arabic": "árabe",
        "UpRoman": "UpRoman",
        "LowRoman": "LowRoman",
        "UpLetter": "UpLetter",
        "LowLetter": "LowLetter",
        "Number": "Número",
        "Leading zero": "Zero inicial",
        "Bullet": "Bala",
        "Ordinal": "Ordinal",
        "Ordinal Text": "Texto Ordinal",
        "For East": "Para o leste",
        "No Restart": "Não reiniciar",
        "Font": "Fonte",
        "Font style": "Estilo de fonte",
        "Underline style": "Estilo sublinhado",
        "Font color": "Cor da fonte",
        "Effects": "Efeitos",
        "Strikethrough": "Tachado",
        "Superscript": "Sobrescrito",
        "Subscript": "Subscrito",
        "Double strikethrough": "Tachado duplo",
        "Regular": "Regular",
        "Bold": "Negrito",
        "Italic": "itálico",
        "Cut": "Cortar",
        "Copy": "cópia de",
        "Paste": "Colar",
        "Hyperlink": "Hiperlink",
        "Open Hyperlink": "Abrir hiperlink",
        "Copy Hyperlink": "Copiar hiperlink",
        "Remove Hyperlink": "Remover hiperlink",
        "Paragraph": "Parágrafo",
        "Linked Style": "Vinculado (parágrafo e caractere)",
        "Character": "Personagem",
        "Merge Cells": "Mesclar células",
        "Insert Above": "Inserir acima",
        "Insert Below": "Inserir abaixo",
        "Insert Left": "Inserir à esquerda",
        "Insert Right": "Inserir à direita",
        "Delete": "Excluir",
        "Delete Table": "Excluir tabela",
        "Delete Row": "Excluir linha",
        "Delete Column": "Excluir coluna",
        "File Name": "Nome do arquivo",
        "Format Type": "Tipo de formato",
        "Save": "Salve ",
        "Navigation": "Navegação",
        "Results": "Resultados",
        "Replace": "Substituir",
        "Replace All": "Substitua tudo",
        "We replaced all": "Nós substituímos todos",
        "Find": "Encontrar",
        "No matches": "Sem combinações",
        "All Done": "Tudo feito",
        "Result": "Resultado",
        "of": "do",
        "instances": "instâncias",
        "with": "com",
        "Click to follow link": "Clique para seguir o link",
        "Continue Numbering": "Continuar numeração",
        "Bookmark name": "Nome do marcador",
        "Close": "Fechar",
        "Restart At": "Reiniciar em",
        "Properties": "Propriedades",
        "Name": "Nome",
        "Style type": "Tipo de estilo",
        "Style based on": "Estilo baseado em",
        "Style for following paragraph": "Estilo para o parágrafo a seguir",
        "Formatting": "Formatação",
        "Numbering and Bullets": "Numeração e marcadores",
        "Numbering": "Numeração",
        "Update Field": "Atualizar campo",
        "Edit Field": "Editar campo",
        "Bookmark": "marca páginas",
        "Page Setup": "Configurações da página",
        "No bookmarks found": "Nenhum marcador encontrado",
        "Number format tooltip information": "Formato numérico de nível único: </br> [PREFIX]% [LEVELNUMBER] [SUFFIX] </br> Por exemplo, 'Capítulo% 1'. exibirá numeração como </br> Capítulo 1. Item </br> Capítulo 2. Item </br>… </br> Capítulo N. Item </br> </br> Formato de número multinível: </br> [ PREFIXO]% [NÚMERO DE NÍVEL] [SUFIXO] + [PREFIXO]% [NÚMERO DE NÍVEL] [SUFIXO] </br> Por exemplo, '% 1.% 2.' exibirá numeração como </br> 1.1. Item </br> 1.2. Item </br>… </br> 1.N. Item",
        "Format": "Formato",
        "Create New Style": "Criar novo estilo",
        "Modify Style": "Modificar estilo",
        "New": "Novo",
        "Bullets": "Balas",
        "Use bookmarks": "Usar marcadores",
        "Table of Contents": "Índice",
        "AutoFit": "Ajuste automático",
        "AutoFit to Contents": "Ajustar automaticamente ao conteúdo",
        "AutoFit to Window": "Ajustar automaticamente à janela",
        "Fixed Column Width": "Largura fixa da coluna",
        "Reset": "Redefinir",
        "Match case": "Caso de compatibilidade",
        "Whole words": "Palavras inteiras",
        "Add": "Adicionar",
        "Go To": "Vamos para",
        "Search for": "Procurar por",
        "Replace with": "Substituir com",
        "TOC 1": "TOC 1",
        "TOC 2": "TOC 2",
        "TOC 3": "TOC 3",
        "TOC 4": "TOC 4",
        "TOC 5": "TOC 5",
        "TOC 6": "TOC 6",
        "TOC 7": "TOC 7",
        "TOC 8": "TOC 8",
        "TOC 9": "TOC 9",
        "Right-to-left": "Direita para esquerda",
        "Left-to-right": "Esquerda para a direita",
        "Direction": "Direção",
        "Table direction": "Direção da mesa",
        "Indent from right": "Recuar da direita",
        "Contextual Spacing": "Não adicione espaço entre os parágrafos dos mesmos estilos",
        "Password Mismatch": "A senha não corresponde",
        "Restrict Editing": "Restringir edição",
        "Formatting restrictions": "Restrições de formatação",
        "Allow formatting": "Permitir formatação",
        "Editing restrictions": "Restrições de edição",
        "Read only": "Somente leitura",
        "Exceptions Optional": "Exceções (opcional)",
        "Select Part Of Document And User": "Selecione partes do documento e escolha usuários com permissão para editá-los livremente.",
        "Everyone": "Todos",
        "More users": "Mais usuários",
        "Add Users": "Adicionar usuários",
        "Enforcing Protection": "Sim, comece a impor proteção",
        "Start Enforcing Protection": "Comece a impor proteção",
        "Enter User": "Digite o usuário",
        "Users": "Comercial",
        "Enter new password": "Insira a nova senha",
        "Reenter new password to confirm": "Digite novamente a nova senha para confirmar",
        "Your permissions": "Suas permissões",
        "Protected Document": "Este documento está protegido contra edição não intencional. Você pode editar nesta região.",
        "FormFieldsOnly": "Este documento está protegido contra edição não intencional. Você só pode preencher formulários nesta região.",
        "You may format text only with certain styles": "Você pode formatar o texto apenas com certos estilos.",
        "Stop Protection": "Pare a proteção",
        "Password": "Senha",
        "Spelling Editor": "Editor de ortografia",
        "Spelling": "Soletração",
        "Spell Check": "Verificação Ortográfica",
        "Underline errors": "Sublinhar erros",
        "Ignore": "Ignorar",
        "Ignore all": "Ignore tudo",
        "Add to Dictionary": "Adicionar ao dicionário",
        "Change": "mudança",
        "Change All": "Mude tudo",
        "Suggestions": "Sugestões",
        "The password is incorrect": "A senha está incorreta",
        "Error in establishing connection with web server": "Erro ao estabelecer conexão com o servidor web",
        "Highlight the regions I can edit": "Destaque as regiões que posso editar",
        "Show All Regions I Can Edit": "Mostrar todas as regiões que posso editar",
        "Find Next Region I Can Edit": "Encontre a próxima região que posso editar",
        "Keep source formatting": "Mantenha a formatação de origem",
        "Match destination formatting": "Corresponder à formatação do destino",
        "Text only": "Somente texto",
        "Comments": "Comentários",
        "Type your comment": "Digite seu comentário",
        "Post": "Postar",
        "Reply": "Resposta",
        "New Comment": "Novo comentário",
        "Edit": "Editar",
        "Resolve": "Resolver",
        "Reopen": "Reabrir",
        "No comments in this document": "Nenhum comentário neste documento",
        "more": "Mais",
        "Type your comment here": "digite seu comentário aqui",
        "Next Comment": "Próximo Comentário",
        "Previous Comment": "Comentário anterior",
        "Un-posted comments": "Comentários não publicados",
        "Discard Comment": "Comentários adicionados não publicados. Se você continuar, esse comentário será descartado.",
        "No Headings": "Nenhum título encontrado!",
        "Add Headings": "Este documento não possui cabeçalhos. Adicione títulos e tente novamente.",
        "More Options": "Mais opções",
        "Click to see this comment": "Clique para ver este comentário",
        "Drop Down Form Field": "Campo suspenso do formulário",
        "Drop-down items": "Itens suspensos",
        "Items in drop-down list": "Itens na lista suspensa",
        "ADD": "ADICIONAR",
        "REMOVE": "RETIRAR",
        "Field settings": "Configurações de campo",
        "Tooltip": "Sugestão",
        "Drop-down enabled": "Drop-down ativado",
        "Check Box Form Field": "Campo de formulário da caixa de seleção",
        "Check box size": "Tamanho da caixa de seleção",
        "Auto": "Auto",
        "Default value": "Valor padrão",
        "Not checked": "Não checado",
        "Checked": "Verificado",
        "Check box enabled": "Caixa de seleção ativada",
        "Text Form Field": "Campo de formulário de texto",
        "Type": "Tipo",
        "Default text": "Texto padrão",
        "Maximum length": "Comprimento máximo",
        "Text format": "Formato de texto",
        "Fill-in enabled": "Preenchimento ativado",
        "Default number": "Número padrão",
        "Default date": "Data padrão",
        "Date format": "Formato de data",
        "Merge Track": "Esta ação não será marcada como alteração. Você quer continuar?",
        "UnTrack": "Não pode ser rastreado",
        "Accept": "Aceitar",
        "Reject": "Rejeitar",
        "Previous Changes": "Alterações anteriores",
        "Next Changes": "Próximas alterações",
        "Inserted": "Inserido",
        "Deleted": "Excluído",
        "Changes": "Alterar",
        "Accept all": "Aceitar tudo",
        "Reject all": "Rejeitar tudo",
        "No changes": "Sem alterações",
        "Accept Changes": "Aceitar alterações",
        "Reject Changes": "Rejeitar alterações",
        "User": "Do utilizador",
        "View": "Visão",
        "Insertion": "Inserção",
        "Deletion": "Eliminação",
        "All caps": "Tudo em maiúsculas",
        "This region is locked by": "Esta região está bloqueada por",
        "Lock": "Fechadura",
        "Unlock": "Desbloquear",
        "Already locked": "Selecionada ou parte da região já está bloqueada por outro usuário",
        "1, 2, 3, ...": "1, 2, 3, ...",
        "a, b, c, ...": "a, b, c, ...",
        "A, B, C, ...": "A, B, C, ...",
        "I, II, III, ...": "I, II, III, ...",
        "i, ii, iii, ...": "i, ii, iii, ...",
        "Text Form": "Formulário de Texto",
        "Check Box": "Caixa de Seleção",
        "DropDown": "Suspenso",
        "Form Fields": "Campos do Formulário",
        "Fillin enabled": "Preenchimento habilitado",
        "Multiple Comment": "Por favor, poste seu comentário",
        "InsertFootnote": "InsertFootnote",
        "InsertEndnote": "InsertEndnote",
        "Footnote": "Nota de rodapé",
        "Endnote": "Nota final",
        "Click to View/Edit Footnote": "Clique para ver / editar nota de rodapé",
        "Click to View/Edit Endnote": "Clique para ver / editar nota final"
    },
    "documenteditorcontainer": {
        "New": "Novo",
        "Open": "Aberto",
        "Undo": "Desfazer",
        "Redo": "Refazer",
        "Image": "Imagem",
        "Table": "Mesa",
        "Link": "Ligação",
        "Bookmark": "marca páginas",
        "Table of Contents": "Índice",
        "HEADING - - - - 1": "CABEÇALLHO 1",
        "HEADING - - - - 2": "RUBRICA - - - - 2",
        "HEADING - - - - 3": "RUBRICA - - - - 3",
        "Header": "Cabeçalho",
        "Footer": "Rodapé",
        "Page Setup": "Configurações da página",
        "Page Number": "Número de página",
        "Break": "Pausa",
        "Find": "Encontrar",
        "Local Clipboard": "Área de transferência local",
        "Restrict Editing": "Restringir edição",
        "Upload from computer": "Fazer upload de computador",
        "By URL": "Por URL",
        "Page Break": "Quebra de página",
        "Section Break": "Quebra de seção",
        "Header And Footer": "Cabeçalho rodapé",
        "Options": "Opções",
        "Levels": "Níveis",
        "Different First Page": "Primeira página diferente",
        "Different header and footer for odd and even pages": "Cabeçalho e rodapé diferentes para páginas ímpares e pares.",
        "Different Odd And Even Pages": "Páginas ímpares e pares diferentes",
        "Different header and footer for first page": "Cabeçalho e rodapé diferentes para a primeira página.",
        "Position": "Posição",
        "Header from Top": "Cabeçalho de cima",
        "Footer from Bottom": "Rodapé de baixo",
        "Distance from top of the page to top of the header": "Distância da parte superior da página até a parte superior do cabeçalho.",
        "Distance from bottom of the page to bottom of the footer": "Distância da parte inferior da página até a parte inferior do rodapé.",
        "Aspect ratio": "Proporção da tela",
        "W": "W",
        "H": "H",
        "Width": "Largura",
        "Height": "Altura",
        "Text": "Texto",
        "Paragraph": "Parágrafo",
        "Fill": "Preencher",
        "Fill color": "Cor de preenchimento",
        "Border Style": "Estilo de borda",
        "Outside borders": "Fronteiras externas",
        "All borders": "Todas as fronteiras",
        "Inside borders": "Fronteiras internas",
        "Left border": "Borda esquerda",
        "Inside vertical border": "Borda vertical interna",
        "Right border": "Borda direita",
        "Top border": "Borda superior",
        "Inside horizontal border": "Borda horizontal interna",
        "Bottom border": "Borda inferior",
        "Border color": "Cor da borda",
        "Border width": "Largura da borda",
        "Cell": "Célula",
        "Merge cells": "Mesclar células",
        "Insert Or Delete": "Inserir / Excluir",
        "Insert columns to the left": "Inserir colunas à esquerda",
        "Insert columns to the right": "Inserir colunas à direita",
        "Insert rows above": "Inserir linhas acima",
        "Insert rows below": "Inserir linhas abaixo",
        "Delete rows": "Excluir linhas",
        "Delete columns": "Excluir colunas",
        "Cell Margin": "Margem celular",
        "Top": "Topo",
        "Bottom": "Inferior",
        "Left": "Esquerda",
        "Right": "Certo",
        "Align Text": "Alinhar texto",
        "Align top": "Alinhar parte superior",
        "Align bottom": "Alinhar parte inferior",
        "Align center": "Alinhar ao centro",
        "Number of heading or outline levels to be shown in table of contents": "Número de níveis de cabeçalho ou estrutura de tópicos a serem mostrados no índice.",
        "Show page numbers": "Mostrar números de página",
        "Show page numbers in table of contents": "Mostrar números de página no índice.",
        "Right align page numbers": "Alinhar à direita os números das páginas",
        "Right align page numbers in table of contents": "Alinhe à direita os números das páginas no sumário.",
        "Use hyperlinks": "Use hiperlinks",
        "Use hyperlinks instead of page numbers": "Use hiperlinks em vez de números de página.",
        "Font": "Fonte",
        "Font Size": "Tamanho da fonte",
        "Font color": "Cor da fonte",
        "Text highlight color": "Cor do realce do texto",
        "Clear all formatting": "Limpar toda a formatação",
        "Bold Tooltip": "Negrito (Ctrl + B)",
        "Italic Tooltip": "Itálico (Ctrl + I)",
        "Underline Tooltip": "Sublinhado (Ctrl + U)",
        "Strikethrough": "Tachado",
        "Superscript Tooltip": "Sobrescrito (Ctrl + Shift ++)",
        "Subscript Tooltip": "Subscrito (Ctrl + =)",
        "Align left Tooltip": "Alinhar à esquerda (Ctrl + L)",
        "Center Tooltip": "Centro (Ctrl + E)",
        "Align right Tooltip": "Alinhar à direita (Ctrl + R)",
        "Justify Tooltip": "Justificar (Ctrl + J)",
        "Decrease indent": "Recuar devagar",
        "Increase indent": "Aumentar recuo",
        "Line spacing": "Espaçamento entre linhas",
        "Bullets": "Balas",
        "Numbering": "Numeração",
        "Styles": "Estilos",
        "Manage Styles": "Gerenciar estilos",
        "Page": "Página",
        "of": "do",
        "Fit one page": "Ajustar uma página",
        "Spell Check": "Verificação Ortográfica",
        "Underline errors": "Sublinhar erros",
        "Fit page width": "Ajustar largura da página",
        "Update": "Atualizar",
        "Cancel": "Cancelar",
        "Insert": "Inserir",
        "No Border": "Sem borda",
        "Create a new document": "Crie um novo documento.",
        "Open a document": "Abra um documento.",
        "Undo Tooltip": "Desfazer a última operação (Ctrl + Z).",
        "Redo Tooltip": "Refaça a última operação (Ctrl + Y).",
        "Insert inline picture from a file": "Inserir imagem embutida de um arquivo.",
        "Insert a table into the document": "Inserir uma tabela no documento",
        "Create Hyperlink": "Crie um link no seu documento para acesso rápido a páginas e arquivos da Web (Ctrl + K).",
        "Insert a bookmark in a specific place in this document": "Insira um marcador em um local específico neste documento.",
        "Provide an overview of your document by adding a table of contents": "Forneça uma visão geral do seu documento adicionando um índice.",
        "Add or edit the header": "Adicione ou edite o cabeçalho.",
        "Add or edit the footer": "Adicione ou edite o rodapé.",
        "Open the page setup dialog": "Abra a caixa de diálogo de configuração da página.",
        "Add page numbers": "Adicione números de página.",
        "Find Text": "Encontre o texto no documento (Ctrl + F).",
        "Toggle between the internal clipboard and system clipboard": "Alterne entre a área de transferência interna e a área de transferência do sistema. </br> O acesso à área de transferência do sistema por meio de script é negado devido à política de segurança dos navegadores. </br> 1. Você pode ativar a área de transferência interna para cortar, copiar e colar dentro do componente. 2. Você pode usar os atalhos do teclado (Ctrl + X, Ctrl + C e Ctrl + V) para cortar , copie e cole com a área de transferência do sistema.",
        "Current Page Number": "O número da página atual no documento. Clique ou toque para navegar em uma página específica.",
        "Read only": "Somente leitura",
        "Protections": "Proteções",
        "Error in establishing connection with web server": "Erro ao estabelecer conexão com o servidor web",
        "Single": "solteiro",
        "Double": "Duplo",
        "New comment": "Novo comentário",
        "Comments": "Comentários",
        "Print layout": "Layout de impressão",
        "Web layout": "Layout da Web",
        "Text Form": "Formulário de texto",
        "Check Box": "Caixa de verificação",
        "DropDown": "Suspenso",
        "Update Fields": "Campos de atualização",
        "Update cross reference fields": "Atualizar campos de referência cruzada",
        "Hide properties pane": "Ocultar painel de propriedades",
        "Show properties pane": "Mostrar painel de propriedades",
        "Form Fields": "Campos do Formulário",
        "Track Changes": "Acompanhe as alterações feitas no documento",
        "TrackChanges": "Alterações de percurso",
        "AllCaps": "Tudo em maiúsculas",
        "Change case Tooltip": "Mudar maiúsculas e minúsculas",
        "Insert Footnote": "Inserir nota de rodapé",
        "Insert Endnote": "Inserir nota final",
        "Footnote Tooltip": "Inserir nota de rodapé (Alt + Ctrl + F).",
        "Endnote Tooltip": "Inserir nota final (Alt + Ctrl + D)."
    },
    "toast": {
        "close": "Fechar"
    },
    "kanban": {
        "items": "Itens",
        "min": "Mín.",
        "max": "Máx.",
        "cardsSelected": "Cartões Selecionados",
        "addTitle": "Adicionar novo cartão",
        "editTitle": "Editar detalhes do cartão",
        "deleteTitle": "Excluir cartão",
        "deleteContent": "Tem certeza de que deseja excluir este cartão?",
        "save": "Salve ",
        "delete": "Excluir",
        "cancel": "Cancelar",
        "yes": "sim",
        "no": "Não",
        "close": "Perto",
        "noCard": "Nenhum cartão para exibir"
    },
    "drop-down-tree": {
        "noRecordsTemplate": "Nenhum registro foi encontrado",
        "actionFailureTemplate": "Pedido falhou",
        "overflowCountTemplate": "+ $ {count} mais ..",
        "totalCountTemplate": "$ {count} selecionado"
    }

  }
});

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('app');

const render = messages => {
  ReactDOM.render(
    <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
      <Provider store={store}>
        <LanguageProvider messages={messages}>
            <ConnectedRouter history={history}>
                <App history={history} />
            </ConnectedRouter>
        </LanguageProvider>
      </Provider>
    </SnackbarProvider>,
    MOUNT_NODE,
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', 'containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import('intl'));
  })
    .then(() => Promise.all([
      import('intl/locale-data/jsonp/en.js'),
      import('intl/locale-data/jsonp/es.js')
    ]))
    .then(() => render(translationMessages))
    .catch(err => {
      throw err;
    });
} else {
  render(translationMessages);
}

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production') {
  require('offline-plugin/runtime').install(); // eslint-disable-line global-require
}

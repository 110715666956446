const example = {
  key: 'errors',
  name: 'Errors',
  icon: 'ios-paw-outline',
  child: [
    {
      key: 'not_found_page',
      name: 'Not Found Page',
      badge: 'Hot',
      link: '/app/pages/not-found'
    },
    {
      key: 'error_page',
      name: 'Error Page',
      link: '/app/pages/error'
    },
  ]
};

const homeItem = {
  key: 'home',
  name: 'Dashboard',
  icon: 'ios-pie-outline',
  link: '/app',
  type: 1
};
const generalItem = {
  key: 'general',
  name: 'General',
  icon: 'ios-grid-outline',
  link: '/app/general',
  type: 1
};
//icon: 'ios-easel-outline',
const townItem = {
  key: 'town',
  name: 'Town',
  icon: 'ios-keypad-outline',
  type: 1,
  child: [
    {
      key: 'townStatus',
      name: 'Status',
      link: '/app/town'
    },
    {
      key: 'townStatistics',
      name: 'Statistics',
      link: '/app/town/statistics'
    },
    {
      key: 'townMap',
      name: 'Map',
      link: '/app/town/map'
    },
  ]
};
const collaboratorsItem = {
  key: 'collaborators',
  name: 'Collaborators',
  icon: 'ios-pulse',
  link: '/app/collaborators',
  type: 2
};
//icon: 'ios-paper-outline',
const prodItem = {
  key: 'prod',
  name: 'Production',
  icon: 'ios-clock-outline',
  type: 2,
  child: [
    {
      key: 'analyst',
      name: 'Analyst',
      link: '/app/production/analyst'
    },
    {
      key: 'signer',
      name: 'Signer',
      link: '/app/production/signer'
    }

  ]
};
const invoicesItem = {
  key: 'invoices',
  name: 'Invoices',
  icon: 'ios-cash-outline',
  link: '/app/finance/invoices',
  type: 2
};
const manage = {
  key: 'manage',
  name: 'Manage',
  icon: 'ios-link-outline',
  type: 2,
  child: [
    {
      key: 'users',
      name: 'Users',
      link: '/app/manage/users'
    },
    {
      key: 'companies',
      name: 'Companies',
      link: '/app/manage/companies'
    }

  ]
};
//icon: 'ios-create-outline',
const statisticsItem = {
  key: 'statistics',
  name: 'Statistics',
  icon: 'ios-stats-outline',
  link: '/app/statistics',
  type: 2
};

const menuItems = [
  homeItem, // all roles
  generalItem, // all roles
  townItem, // all roles
  prodItem, // all roles
  collaboratorsItem, // all role
  statisticsItem, // all roles
  invoicesItem, // only role 'admin'
  manage, // only role 'admin'
];

module.exports = menuItems;

import React from 'react';
import { PropTypes } from 'prop-types';
import { Switch, Route } from 'react-router-dom';

import Dashboard from '../Templates/Dashboard';
import AuthRoute from './AuthRoute';
import {
  Maintenance,
  Error,
  NotFound,
  Login,
  Profile,
  Home, General, Town, TownStatistics, TownMap, Collaborators, Production, Quality, Statistics,
  UsersVerification, CompaniesVerification, UserRegistration, CompanyRegistration, CompanyModification, Invoices
} from '../pageListAsync';

class Application extends React.Component {
  render() {
    const { changeMode, history } = this.props;
    return (
      <Dashboard history={history} changeMode={changeMode}>
        <Switch>
          { /* Login */ }
          <Route exact path="/login" component={Login} />
          { /* Home */ }
          <AuthRoute exact path="/app" component={Home} />
          { /* Info */ }
          <AuthRoute path="/app/general" component={General} />
          <AuthRoute exact path="/app/town" component={Town} />
          <AuthRoute path="/app/town/statistics" component={TownStatistics} />
          <AuthRoute path="/app/town/map" component={TownMap} />
          <AuthRoute path="/app/production/analyst" component={Production} />
          <AuthRoute path="/app/production/signer" component={Quality} />
          <AuthRoute path="/app/collaborators" component={Collaborators} />
          <AuthRoute path="/app/statistics" component={Statistics} />
          { /* Admin */ }
          <AuthRoute exact path="/app/manage/users" component={UsersVerification} />
          <AuthRoute path="/app/manage/users/user-registration" component={UserRegistration} />
          <AuthRoute exact path="/app/manage/companies" component={CompaniesVerification} />
          <AuthRoute path="/app/manage/companies/company-registration" component={CompanyRegistration} />
          <AuthRoute path="/app/manage/companies/company-modification" component={CompanyModification} />
          <AuthRoute exact path="/app/finance/invoices" component={Invoices} />
          { /* Profiles */ }
          <AuthRoute path="/app/profile" component={Profile} />
          
          <Route path="/error" component={Error} />
          { /* Default */ }
          <Route component={NotFound} />
        </Switch>
      </Dashboard>
    );
  }
}

Application.propTypes = {
  changeMode: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default Application;

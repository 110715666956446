/* eslint-disable linebreak-style */
import axios from 'axios';
import get from 'lodash/get';


function setParams(config) {
  const headers = get(config, 'headers', {});
  const sStorage = JSON.parse(sessionStorage.getItem('sStorage'));
  headers.common.Authorization = `Bearer ${get(sStorage, 'token', '')}`;
  let result = { ...config, headers };

  return result;
}

const API = axios.create({
  baseURL: 'https://gistudioapi.azurewebsites.net/api',
});

//API.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

API.interceptors.request.use(
  (config) => setParams(config),
  (error) => Promise.reject(error)
);

export default API;

import * as types from '../constants/contractConstants';

export const initContract = payload => ({
  type: types.LOGIN,
  payload,
});

export const cleanContract = payload => ({
  type: types.LOGOUT,
  payload,
});

/* eslint-disable linebreak-style */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import get from 'lodash/get';
import { withSnackbar } from 'notistack';
import { login, logout } from 'dan-redux/actions/reduxLoginActions';
import Type from 'dan-styles/Typography.scss';
/* core */
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  Paper
} from '@material-ui/core';
/* our */
import { LoadingState } from 'dan-components';
import AxiosAPI from 'dan-api/axiosAPI';
import { ROLE } from '../../../../i18n';
import SyncfusionChart from '../Common/SyncfusionChart';

import styles from './styles-jss';


class View extends React.Component {
  state = {
    data: [],
    loading: true,
    date: new Date(),
    selectedDate: '2000-01-01',
    defaultAverage: 500
  }

  _isMounted = false;

  componentDidMount = () => {
    this._isMounted = true;
    this.loadData();

    if (this._isMounted === true) {
      this.timerID = setInterval(
        () => this.tick(),
        1000
      );
    }
    if (this._isMounted === true) {
      this.timerID2 = setInterval(
        () => this.tick2(),
        1000 * 60 * 30
      );
    }
  }

  componentWillMount = () => {
    clearInterval(this.timerID);
    clearInterval(this.timerID2);
  }

  componentWillUnmount = () => {
    clearInterval(this.timerID);
    clearInterval(this.timerID2);
    this._isMounted = false;
  }

  tick = () => {
    this.setState({ date: new Date() });
  }

  tick2 = () => {
    const { selectedDate, defaultAverage } = this.state;
    this.getAPI(selectedDate, defaultAverage);
    console.log('tick2 executed at ' + new Date().toLocaleString());
  }

  loadData = async () => {
    const newDate = new Date();
    const day = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();

    const selectedDate = year + '-' + (month.length === 1 ? '0' + month : month) + '-' + (day.length === 1 ? '0' + day : day);
    const { defaultAverage } = this.state;
    await this.getAPI(selectedDate, defaultAverage);
    if (this._isMounted === true) this.setState({ loading: false, selectedDate });
  }

  getAPI = async (stringDate, defaultAverage) => {
    const {
      enqueueSnackbar,
      intl,
      login: loginAction,
      logout: logoutAction,
      contract,
      contractList,
      history
    } = this.props;

    let data = [];
    const companyId = contractList[contract].companyId;

    const result = await AxiosAPI.getViewerInformation(companyId, stringDate, loginAction);
    if (this._isMounted === true) {
      if (result.code !== '200') {
        enqueueSnackbar(intl.formatMessage({ id: 'Daily information was not loaded' }), { variant: 'error' });
        enqueueSnackbar(intl.formatMessage({ id: result.error.message }), { variant: 'error' });
        if(result.code === '401')
        {
          logoutAction();
          history.push('/');
        }
      } else {
        const { response } = result;
        const defaultAverageLabel = intl.formatMessage({ id: 'Expected of Average' });
        if (response.length > 0)
        {
          data.push({ type: 'column', name: 'Pontos', data: [] });
          data.push({ type: 'line', name: defaultAverageLabel, data: [] });
          for (let index = 0; index < response.length; index++) {
            const element = response[index];
            data[0].data.push({ x: element.label, y: element.totalPoles });
            data[1].data.push({ x: element.label, y: defaultAverage });
          }
        }
      }
    }
    if (this._isMounted === true) this.setState({ data });
  }

  render() {
    const {
      classes,
      intl
    } = this.props;

    const {
      loading,
      data,
      date
    } = this.state;

    
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const date_string = (day.length === 1 ? '0' + day : day) + '-' + (month.length === 1 ? '0' + month : month) + '-' + year;

    return (
      <div align="center">
        {
          loading
            ? (<LoadingState loading={loading} />)
            : (
              <div>
                <div style={{ marginTop: 15 }}>
                  <Paper className={classes.internal100Div}>
                    <div style={{ padding: 10 }}>
                      <Typography variant="h5" className={Type.medium} color="primary" gutterBottom>
                        {
                          `${intl.formatMessage({ id: 'Production' })} (${date_string} ${date.toLocaleTimeString()})`
                        }
                      </Typography>
                      <SyncfusionChart data={data} id="viewChart" title='' />
                    </div>
                  </Paper>
                </div>
              </div>
            )
        }
      </div>
    );
  }
}

View.propTypes = {
  intl: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  contract: PropTypes.number,
  contractList: PropTypes.array,
  login: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
  location: PropTypes.object,
  enqueueSnackbar: PropTypes.func.isRequired
};

View.defaultProps = {
  contract: 0,
  contractList: [],
  location: {}
};

const constDispatchToProps = dispatch => ({
  login: bindActionCreators(login, dispatch),
  logout: bindActionCreators(logout, dispatch),
});

const ViewMapped = connect(
  () => ({}),
  constDispatchToProps
)(View);

const mapStateToProps = (state) => ({
  contract: get(JSON.parse(JSON.stringify(state.get('contract'))), 'contractIndex', undefined),
  contractList: get(JSON.parse(JSON.stringify(state.get('login'))), 'contractList', []),
});

export default withSnackbar(compose(withStyles(styles), connect(mapStateToProps))(injectIntl(ViewMapped)));

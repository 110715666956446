/* eslint-disable linebreak-style */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Type from 'dan-styles/Typography.scss';
import {
  ChartComponent,
  Inject,
  ColumnSeries,
  Legend,
  SeriesCollectionDirective,
  SeriesDirective,
  Tooltip,
  Category,
  DataLabel,
  LineSeries
} from '@syncfusion/ej2-react-charts';
import styles from './fluidChart-jss';


function SyncfusionChart(props) {
  const { classes, data, id, title } = props;
  const emptyLabel = 'Sem dados para exibir';

  return (
      <div style={{height: '100%'}}>
        <div style={{ textAlign: 'center', height: '5%' }}>
          <Typography variant="h5" className={Type.medium} color="primary" gutterBottom>
            { title }
          </Typography>
        </div>
        {
          data.length > 0
          ? (
              <div className={classes.chartFluid}>
                <ChartComponent
                  id={id}
                  style={{ textAlign: "center" }}
                  primaryXAxis={{
                    valueType: 'Category',
                    interval: 1,
                    majorGridLines: { width: 0 },
                    enableTrim: false,
                    labelIntersectAction: 'Rotate90'
                  }}
                  chartArea={{ border: { width: 0 } }}
                  enableSideBySidePlacement={true}
                  tooltip={{ enable: true, shared: true }}
                >
                  <Inject services={[ColumnSeries, LineSeries, Category, Legend, Tooltip, DataLabel]} />
                  <SeriesCollectionDirective>
                    {
                      data.map( (item, index) => {
                        return(
                          item.type === 'line'
                          ? (
                            <SeriesDirective
                              key={index}
                              dataSource={item.data}
                              xName='x'
                              yName='y'
                              name={item.name}
                              type='Line'
                              width={2}
                              marker={{ visible: true }}
                              cornerRadius={{ topLeft: 10, topRight: 10 }}
                            />
                          ) : (
                            <SeriesDirective
                              key={index}
                              dataSource={item.data}
                              xName='x'
                              yName='y'
                              name={item.name}
                              type='Column'
                              marker={{
                                visible: true,
                                shape: 'None',
                                dataLabel: {
                                  visible: true,
                                  position: 'Outer',
                                  font: { fontWeight: '600', color: '#00000' },
                                  //template: CustomLabel
                                }
                              }}
                              cornerRadius={{ topLeft: 10, topRight: 10 }}
                            />
                          )
                        );
                      })
                    }
                  </SeriesCollectionDirective>
                </ChartComponent>
              </div>
              )
              : (<div style={{height:'95%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                  <Typography variant='h5'>{emptyLabel}</Typography>
                </div>)
          }
        </div>
  );
}

SyncfusionChart.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  data: PropTypes.array,
  id: PropTypes.string,
  title: PropTypes.string
};
SyncfusionChart.defaultProps = {
  data: [],
  id: 'SyncfusionChartId',
  title: ''
};

export default withStyles(styles)(SyncfusionChart);

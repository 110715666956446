import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import get from 'lodash/get';
import Ionicon from 'react-ionicons';
/* core */
import {
  Hidden,
  AppBar,
  Toolbar,
  Fab,
  Tooltip,
  IconButton,
  Typography
} from '@material-ui/core';
/* icons */
import MenuIcon from '@material-ui/icons/Menu';

import UserMenu from './UserMenu';
import styles from './header-jss';

const elem = document.documentElement;

class Header extends React.Component {
  state = {
    open: false,
    fullScreen: false,
    turnDarker: false,
    showTitle: false
  };

  // Initial header style
  flagDarker = false;

  flagTitle = false;

  componentDidMount = () => {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const doc = document.documentElement;
    const scroll = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    const newFlagDarker = (scroll > 30);
    const newFlagTitle = (scroll > 40);
    if (this.flagDarker !== newFlagDarker) {
      this.setState({ turnDarker: newFlagDarker });
      this.flagDarker = newFlagDarker;
    }
    if (this.flagTitle !== newFlagTitle) {
      this.setState({ showTitle: newFlagTitle });
      this.flagTitle = newFlagTitle;
    }
  }

  openFullScreen = () => {
    this.setState({ fullScreen: true });
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
      elem.msRequestFullscreen();
    }
  };

  closeFullScreen = () => {
    this.setState({ fullScreen: false });
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  render() {
    const {
      classes,
      toggleDrawerOpen,
      margin,
      position,
      gradient,
      intl,
      history,
      contract,
      contractList,
    } = this.props;

    const {
      fullScreen,
      open,
      turnDarker,
      showTitle
    } = this.state;

    const setMargin = (sidebarPosition) => {
      if (sidebarPosition === 'right-sidebar') {
        return classes.right;
      }
      return classes.left;
    };

    return (
      <AppBar
        className={
          classNames(
            classes.appBar,
            classes.floatingBar,
            margin && classes.appBarShift,
            setMargin(position),
            turnDarker && classes.darker,
            gradient ? classes.gradientBg : classes.solidBg
          )
        }
      >
        <Toolbar disableGutters={!open}>
          <Fab
            size="small"
            className={classes.menuButton}
            aria-label="Menu"
            onClick={toggleDrawerOpen}
          >
            <MenuIcon />
          </Fab>
          <Hidden smDown>
            <div className={classes.headerProperties}>
              <div className={classNames(classes.headerAction, showTitle && classes.fadeOut)}>
                <div className="display-flex justify-content-space-between align-items-center flex-direction-column">
                  {fullScreen ? (
                    <Tooltip title={intl.formatMessage({ id: 'Exit Full Screen' })} placement="bottom">
                      <IconButton className={classes.button} onClick={this.closeFullScreen}>
                        <Ionicon icon="ios-qr-scanner" />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title={intl.formatMessage({ id: 'Full Screen' })} placement="bottom">
                      <IconButton className={classes.button} onClick={this.openFullScreen}>
                        <Ionicon icon="ios-qr-scanner" />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
          </Hidden>
          <div style={{ display: 'flex', justifyContent: 'space-around', flex: 1, overflow: 'hidden', position: 'relative', alignItems: 'center' }}>
            <Tooltip title={contractList && contractList.length > 0 ? contractList[contract].description : ''} >
              <Typography variant='body1'  >{contractList && contractList.length > 0 ? contractList[contract].contractName : ''}</Typography>
            </Tooltip>
            
          </div>
          <UserMenu history={history} />
        </Toolbar>
      </AppBar>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  toggleDrawerOpen: PropTypes.func.isRequired,
  margin: PropTypes.bool.isRequired,
  gradient: PropTypes.bool.isRequired,
  position: PropTypes.string.isRequired,
  intl: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  contract: PropTypes.number,
  contractList: PropTypes.array,
};

Header.defaultProps = {
  contract: 0,
  contractList: [],
};

const mapStateToProps = (state) => ({
  contract: get(JSON.parse(JSON.stringify(state.get('contract'))), 'contractIndex', undefined),
  contractList: get(JSON.parse(JSON.stringify(state.get('login'))), 'contractList', []),
});

export default compose(withStyles(styles), connect(mapStateToProps))(injectIntl(Header));

/* eslint-disable linebreak-style */
import validator from 'validator';

class Utils {

  static validateTextField(value) {
    if (value === null) return false;
    return (/^[A-Z0-9]+$/i.test(value) && value.length < 254);
  }

  static validateNumberField(value) {
    if (value === null) return false;
    return (/^[0-9]+$/i.test(value) && value.length < 254);
  }

  static validateEmail(value) {
    if (value === null) return false;
    //return (/^[a-z0-9!#$%&'*+/=?^_‘{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_‘{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(value) && value.length < 254);
    return (validator.isEmail(value) && value.length < 254);
  }

  static parseDate(dateInNumber) {
    const date = dateInNumber.toString();
    if (date.length === 6) return date.substring(0, 2) + ':' + date.substring(2, 4) + ':' + date.substring(4);
    if (date.length === 5) return '0' + date.substring(0, 1) + ':' + date.substring(1, 3) + ':' + date.substring(3);
    if (date.length === 4) return '00:' + date.substring(0, 2) + ':' + date.substring(2);
    if (date.length === 3) return '00:0' + date.substring(0, 1) + ':' + date.substring(1);
    if (date.length === 2) return '00:00:' + date;
    return '00:00:0' + date;
  }
}

export default Utils;

/* eslint-disable linebreak-style */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
/* core */
import Fade from '@material-ui/core/Fade';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    margin: 25,
    paddingBottom: 20
  }
});

function LoadingState(props) {
  const { classes, loading, text } = props;

  return (
    <div className={classes.root}>
      <Fade in={loading} unmountOnExit>
        <Fragment>
          {
            text
              ? (
                <Typography variant="h6">
                  {`${text}...`}
                </Typography>
              ) : (
                null
              )
          }
          <CircularProgress color="secondary" />
        </Fragment>
      </Fade>
    </div>
  );
}

LoadingState.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  text: PropTypes.string,
  loading: PropTypes.bool,
};
LoadingState.defaultProps = {
  text: '',
  loading: true,
};

export default withStyles(styles)(LoadingState);

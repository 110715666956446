import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import NotFound from '../Pages/Standalone/NotFoundDedicated';
import Auth from './Auth';
import Application from './Application';
import LoginDedicated from '../Pages/Standalone/LoginDedicated';
import View from '../Pages/GSTUDIOpi/Viewer/View';
import ThemeWrapper, { AppContext } from './ThemeWrapper';
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;


class App extends React.Component {
  render() {
    return (
      <ThemeWrapper>
        <AppContext.Consumer>
          {(changeMode) => (
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => <LoginDedicated {...props} />}
              />
              <Route
                exact
                path="/login"
                render={(props) => <LoginDedicated {...props} />}
              />
              <Route
                exact
                path="/app/view"
                render={(props) => <View {...props} />}
              />
              <Route
                path="/app"
                render={(props) => <Application {...props} changeMode={changeMode} />}
              />
              <Route component={Auth} />
              <Route component={NotFound} />
            </Switch>
          )}
        </AppContext.Consumer>
      </ThemeWrapper>
    );
  }
};

App.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
};

export default App;

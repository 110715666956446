import { fromJS } from 'immutable';
import get from 'lodash/get';
import { INIT } from '../constants/reduxFormConstants';
import { LOGIN, LOGOUT } from "../constants/loginConstants";
const sStorage = JSON.parse(sessionStorage.getItem('sStorage'));

const initialState = {
  id: get(sStorage, 'id', undefined),
  username: get(sStorage, 'username', ''),
  name: get(sStorage, 'name', ''),
  token: get(sStorage, 'token', ''),
  refreshToken: get(sStorage, 'refreshToken', ''),
  tokenTime: get(sStorage, 'tokenTime', 10),
  contractList: get(sStorage, 'contractList', []),

  phone: get(sStorage, 'phone', ''),
  email: get(sStorage, 'email', ''),
  photo: get(sStorage, 'photo', null),

  remember: get(sStorage, 'remember', false),
};
const initialImmutableState = fromJS(initialState);

export default function reducer(state = initialImmutableState, action = {}) {
  switch (action.type) {
    case INIT:
      return state;
    case LOGIN:
      return state.merge(action.payload);
    case LOGOUT:
      sessionStorage.removeItem('sStorage');
      return fromJS({});
    default:
      return state;
  }
}

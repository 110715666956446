/* eslint-disable linebreak-style */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { injectIntl } from 'react-intl';
/* core */
import {
  TextField,
  Input,
  Button,
  IconButton,
  InputAdornment,
  FormControl,
  Paper,
  Hidden,
  InputLabel
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
/* icons */
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ArrowForward from '@material-ui/icons/ArrowForward';

import logo from 'dan-images/gistudio.svg';
import logo_w from 'dan-images/gistudio_w.svg';
import styles from './user-jss';
/* our */
import { LoadingState } from 'dan-components';
import Utils from '../GSTUDIOpi/Common/Utils';

class LoginForm extends React.Component {
  state = {
    showPassword: false,
    username: '',
    password: ''
  }

  handleClickShowPassword = () => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  }

  handleUsername = () => {
    const { username } = this.state;
    return (username !== '' && (Utils.validateEmail(username) || Utils.validateTextField(username)));
  }

  handlePassword = () => {
    const { password } = this.state;
    if (password !== null && password.length > 254) return false;
    return (password !== '' && password !== null && password.length >= 3);
  }

  render() {
    const {
      classes,
      handleSubmit,
      intl,
      loading
    } = this.props;

    const {
      showPassword,
      username,
      password
    } = this.state;

    return (
      <Fragment>
        <Hidden mdUp>
          <NavLink to="/" className={classNames(classes.brand, classes.outer)}>
            <img src={logo_w} alt="GSTUDIOpi" />
          </NavLink>
        </Hidden>
        <Paper className={classNames(classes.paperWrap, classes.petal)}>
          <Hidden smDown>
            <div className={classes.topBar}>
              <NavLink to="/" className={classes.brand}>
                <img src={logo} alt="GSTUDIOpi" />
              </NavLink>
            </div>
          </Hidden>
          <form className={classes.formWrap}>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                  id="username"
                  name="username"
                  label={`${intl.formatMessage({ id: 'Your Username' })} ${intl.formatMessage({ id: 'or' })} ${intl.formatMessage({ id: 'email' })}`}
                  placeholder={`${intl.formatMessage({ id: 'Your Username' })} ${intl.formatMessage({ id: 'or' })} ${intl.formatMessage({ id: 'email' })}`}
                  value={username}
                  required
                  error={!this.handleUsername()}
                  onChange={(event) => {
                    this.setState({ username: event.target.value });
                  }}
                  className={classes.textField}
                />
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="password">{intl.formatMessage({ id: 'Your Password' })}</InputLabel>
                <Input
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  required
                  error={!this.handlePassword()}
                  onChange={(event) => { this.setState({ password: event.target.value }); }}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff color="secondary" /> : <Visibility color="secondary" />}
                      </IconButton>
                    </InputAdornment>
                  )}
                />
              </FormControl>
            </div>
            <div className="display-flex justify-content-space-between">
              <Button size="small" component={NavLink} to="/reset-password" className={classes.buttonLink}>
                {intl.formatMessage({ id: 'Forgot Password' })}
              </Button>
            </div>
            <div className={classes.btnArea}>
              {
                loading ? (
                  <LoadingState loading={loading} />
                ) : (
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    type="submit"
                    disabled={
                      password === ''
                      || username === ''
                      || !this.handlePassword()
                      || !this.handleUsername()
                    }
                    onClick={handleSubmit({
                      username,
                      password
                    })}
                  >
                    {intl.formatMessage({ id: 'Sign In' })}
                    <ArrowForward
                      className={classNames(classes.rightIcon, classes.iconSmall)}
                    />
                  </Button>
                )
              }
            </div>
          </form>
        </Paper>
      </Fragment>
    );
  }
}

LoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  intl: PropTypes.shape({}).isRequired,
  loading: PropTypes.bool.isRequired
};

export default withStyles(styles)(injectIntl(LoginForm));

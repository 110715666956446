import React from 'react';
import PropTypes from 'prop-types';

import Outer from '../../Templates/Outer';
import Login from '../Users/Login';

class LoginDedicated extends React.Component {
  render() {
    return (
      <Outer>
        <Login {...this.props} />
      </Outer>
    );
  }
};

LoginDedicated.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
};

export default LoginDedicated;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withSnackbar } from 'notistack';
import { injectIntl } from 'react-intl';
import Ionicon from 'react-ionicons';
import UserAvatar from 'react-user-avatar';
import Image from 'react-image-resizer';
import { get } from 'lodash';
import { logout, login } from 'dan-redux/actions/reduxLoginActions';
import { initContract } from 'dan-redux/actions/reduxContractActions';
/* core */
import {
  Menu,
  MenuItem,
  Tooltip,
  Hidden,
  IconButton,
  Badge
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AxiosAPI from 'dan-api/axiosAPI';

import { LangContext } from '../../containers/LanguageProvider';

import styles from './header-jss';
import { DEFAULT_LOCALE, getPageList } from '../../i18n';

const localStyles = theme => ({
  button: {
    margin: theme.spacing(1),
  },
  badge: {
    top: 1,
    right: -15,
    // The border color match the background color.
    border: `2px solid ${
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]
    }`,
  },
});

class UserMenu extends React.Component {
  state = {
    anchorEl: null,
    openMenu: null,
    companyLogo: null,
    companyId: undefined,
  };
  _isMounted = false;

  componentDidMount = () => {
    this._isMounted = true;
    const {
      contractList,
      contract,
      pageList,
      history,
      login: loginAction,
      initContract
    } = this.props;

    let list = pageList;
    let cList = contractList;
    let c = contract;
    let plFlag = false;
    let clFlag = false;
    const sStorage = JSON.parse(sessionStorage.getItem('sStorage'));
    if(pageList.length === 0) {
      list = get(sStorage,'contract.currentPageList', []);
      plFlag = true;
    }
    if(cList && cList.length === 0) {
      cList = get(sStorage,'login.contractList', []);
    }
    if(list.length !== 0 && cList.length > 0) {
      const currentPageList = getPageList(contractList[c].roleName);
      if (plFlag) initContract({ contractIndex: c, currentPageList });
      if (clFlag){
        const payload = {
          contractList: sStorage.contractList,
          email: sStorage.email,
          id: sStorage.id,
          name: sStorage.name,
          notificationCount: sStorage.notificationCount,
          phone: sStorage.phone,
          photo: sStorage.photo,
          refreshToken: sStorage.refreshToken,
          token: sStorage.token,
          tokenTime: sStorage.tokenTime,
          username: sStorage.username,
        };
        loginAction(payload);
      } 
    }
    else {
      history.push('/');
      this._isMounted = false;
      return;
    }
    this.loadData();
  }

  componentWillUnmount = () => {
    this._isMounted = false;
  }

  loadData = async () => {
    const {
      contractList,
      contract
    } = this.props;

    await this.getAPI(contractList[contract].companyId)
  }
  
  getAPI = async (companyId) => {
    const {
      enqueueSnackbar,
      intl,
      login: loginAction,
    } = this.props;

    let companyLogo = null;
    const result = await AxiosAPI.getCompany(companyId, loginAction);
    if (this._isMounted === true) {
      if (result.code !== '200') {
        enqueueSnackbar(intl.formatMessage({ id: 'The company information was not loaded' }), { variant: 'error' });
        enqueueSnackbar(intl.formatMessage({ id: result.error.message }), { variant: 'error' });
        if(result.code === '401')
        {
          this.handleLogOut()
        }
      } else {
        companyLogo = result.response.logo;
      }
    }
    if (this._isMounted === true) await this.setState({ companyLogo, companyId });
  }

  handleMenu = menu => (event) => {
    const { openMenu } = this.state;
    this.setState({
      openMenu: openMenu === menu ? null : menu,
      anchorEl: event.currentTarget
    });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, openMenu: null });
  };

  handleChange = (value) => () => {
    const { initContract, contractList } = this.props;
    const { companyId } = this.state;
    const currentPageList = getPageList(contractList[value].roleName);
    initContract({ contractIndex: value, currentPageList });
    if(companyId !== contractList[value].companyId) {
      this.getAPI(contractList[value].companyId);
    }
    this.handleClose();
  };

  handleLogOut = () => {
    const { history, logout: logoutAction } = this.props;
    logoutAction();
    history.push('/');
  };

  handleProfile = () => {
    const { history } = this.props;
    history.push('/app/profile');
  };

  handleCompanyPhoto = (logo) => {
    if (logo !== null && logo !== 'null' && logo !== '') {
      return 'data:image/png;base64,' + logo;
    }
    return null;
  };

  handlePhoto = (photo) => {
    const { username } = this.props;
    const name = username === '' ? 'GSTUDIOpi' : username;
    if (photo === null || photo === 'null' || photo === '') {
      return (<UserAvatar className="item-margin" size="45" color="#33CC33" name={name} />);
    }
    const value1 = 'data:image/png;base64,' + photo;
    return (<UserAvatar className="item-margin" size="45" color="#33CC33" name={name} src={value1} />);
  };

  render() {
    const {
      intl,
      classes,
      language,
      history,
      photo,
      notificationCount,
      contractList,
      contract
    } = this.props;

    const {
      openMenu,
      anchorEl,
      companyLogo
    } = this.state;

    const companyImage = this.handleCompanyPhoto(companyLogo);

    return (
      <div className="display-flex align-items-center item-margin-left">
        {
          companyImage !== null
            ? (
              <div
                style={{
                  borderRadius: '25px',
                  backgroundColor: 'white',
                  width: 200,
                  overflow: 'Hidden'
                }}
              >
                <Image
                  style={{ borderRadius: '25px' }}
                  src={companyImage}
                  height={45}
                  width={250}
                />
              </div>
            ) : (null)
        }

        {
          //this.handlePhoto(photo)
        }

        <Hidden xsDown>
          <span className={classes.separatorV} />
        </Hidden>

        {
          notificationCount > 0
            ? (
              <Tooltip title={intl.formatMessage({ id: 'Notifications' })} key="noteKey">
                <IconButton
                  className={classes.userMenuButton}
                  onClick={() => {
                    /*
                    let page = '';
                    if (role === ROLE.RESPONSIBLE) {
                      page = '/app/visit-requests/visit-notifications';
                    } else if (role === ROLE.VISITOR) {
                      page = '/app/visits/visit-notifications';
                    }
                    history.push(page);
                    */
                  }}
                >
                  <Badge badgeContent={notificationCount} color="secondary">
                    <Ionicon icon="ios-notifications-outline" />
                  </Badge>
                </IconButton>
              </Tooltip>
            ) : (null)
        }
        {/*
        <Tooltip title={`${intl.formatMessage({ id: 'Language' })} (${language})`} key="langKey">
          <IconButton className={classes.userMenuButton} aria-label="Language" onClick={this.handleMenu('user-lang')}>
            <Ionicon icon="ios-globe-outline" />
          </IconButton>
        </Tooltip>
        <LangContext.Consumer>
          {
            handleChange => (
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={openMenu === 'user-lang'}
                onClose={this.handleClose}
              >
                <MenuItem value="pt" onClick={this.handleChange('pt', handleChange)}>pt</MenuItem>
                <MenuItem value="en" onClick={this.handleChange('en', handleChange)}>en</MenuItem>
                <MenuItem value="es" onClick={this.handleChange('es', handleChange)}>es</MenuItem>
              </Menu>
            )
          }
        </LangContext.Consumer>*/}

        {
          contractList.length <= 1 ? null : (
            <>
              <Tooltip title={contractList && contractList.length > 0 ? contractList[contract].contractName : ''} key="contractKey">
                <IconButton className={classes.userMenuButton} aria-label="Contract" onClick={this.handleMenu('user-contract')} >
                  <Ionicon icon="ios-list-box-outline" />
                </IconButton>
              </Tooltip>
              <Menu
                id="contract-menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={openMenu === 'user-contract'}
                onClose={this.handleClose}
              >
                {
                  contractList.map((contract, index) =>{
                    return (
                      <MenuItem value={contract.id} key={index} onClick={this.handleChange(index)}>
                        {contract.contractName}
                      </MenuItem>
                    );
                  })
                }
              </Menu>
            </>
          )
        }
        {/*
        <Tooltip title={intl.formatMessage({ id: 'My Profile' })} key="profileKey">
          <IconButton className={classes.userMenuButton} aria-label="My Profile" onClick={this.handleProfile}>
            <Ionicon icon="ios-contact-outline" />
          </IconButton>
        </Tooltip>
        */}
        <Tooltip title={intl.formatMessage({ id: 'Log Out' })} key="logoutKey">
          <IconButton className={classes.userMenuButton} aria-label="Log Out" onClick={this.handleLogOut}>
            <Ionicon icon="ios-log-out" />
          </IconButton>
        </Tooltip>
      </div>
    );
  }
}

UserMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.shape({}).isRequired,
  language: PropTypes.string,
  username: PropTypes.string,
  photo: PropTypes.string,
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  login: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  notificationCount: PropTypes.number,
  contractList: PropTypes.array,
  pageList: PropTypes.array,
  initContract: PropTypes.func.isRequired,
  contract: PropTypes.number
};

UserMenu.defaultProps = {
  language: 'pt',
  contract: 0,
  pageList: [],
  username: '',
  photo: null,
  notificationCount: 0,
  contractList: [],
};

const constDispatchToProps = dispatch => ({
  login: bindActionCreators(login, dispatch),
  logout: bindActionCreators(logout, dispatch),
  initContract: bindActionCreators(initContract, dispatch)
});

const UserMenuMapped = connect(
  () => ({}),
  constDispatchToProps
)(UserMenu);

const mapStateToProps = (state) => ({
  language: get(JSON.parse(JSON.stringify(state.get('language'))), 'locale', DEFAULT_LOCALE),
  photo: get(JSON.parse(JSON.stringify(state.get('login'))), 'photo', null),
  username: get(JSON.parse(JSON.stringify(state.get('login'))), 'username', ''),
  notificationCount: get(JSON.parse(JSON.stringify(state.get('login'))), 'notificationCount', 0),
  contractList: get(JSON.parse(JSON.stringify(state.get('login'))), 'contractList', []),
  pageList: get(JSON.parse(JSON.stringify(state.get('contract'))), 'currentPageList', []),
  contract: get(JSON.parse(JSON.stringify(state.get('contract'))), 'contractIndex', 0)
});

export default withSnackbar(compose(withStyles(styles, localStyles), connect(mapStateToProps))(injectIntl(UserMenuMapped)));


/* eslint-disable linebreak-style */
function pdd (theme, count) {
    return theme.spacing(1) * count;
  };

const styles = theme => ({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      flexBasis: 300,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 220,
    },
    divFlex:{
      marginTop: 15,
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      alignContent: 'flex-start',
      justifyContent: 'space-between',
    },
    internal50DivLeft:{
        height: '100%',
        minHeight: '100%',

        marginRight: pdd(theme, 1),
        width: `calc(50% - ${pdd(theme, 1)}px)`,
    },
    internal50DivRight:{
        height: '100%',
        minHeight: '100%',

        marginLeft: pdd(theme, 1),
        width: `calc(50% - ${pdd(theme, 1)}px)`,
    },
    internal100Div:{
        marginTop: pdd(theme, 1),
        height: `calc(100% - ${pdd(theme, 1)}px)`,

        width: '100%',
    },
    total:{
        height: '100%',
        minHeight: '100%',
        width: '100%',
        minWidth: '100%'
    },
    customCell:{
      background: '#d7f0f4'
    },
    customBox:{
      border: '1px solid rgba(0,0,0,0.32)',
      borderRadius: 8,
      padding: 8,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      margin: 8,
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
    }
  });
  
export default styles;
  
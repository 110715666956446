import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import Ionicon from 'react-ionicons';
import get from 'lodash/get';

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Collapse
} from '@material-ui/core';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import styles from './sidebar-jss';

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) { // eslint-disable-line
  return <NavLink to={props.to} {...props} innerRef={ref} />; // eslint-disable-line
});

// eslint-disable-next-line
class MainMenu extends React.Component {
  collapseOpen = true;

  handleMenu = (menuArray) => {
    const { pageList } = this.props;
    const result = [];
    menuArray.forEach(element => {
      if (pageList.includes(element.key))
      {
        let temp = JSON.parse( JSON.stringify( element ) );
        if(element.hasOwnProperty('child'))
        {
          temp.child = this.handleMenu(element.child);
        }
        result[result.length] = temp;
      }
    });
    return result;
  }

  handleClick() {
    const {
      toggleDrawerOpen,
      //loadTransition
    } = this.props;
    toggleDrawerOpen();
    //loadTransition(false);
  }

  render() {
    const {
      classes,
      openSubMenu,
      open,
      dataMenu,
      intl
    } = this.props;

    const getMenus = menuArray => menuArray.map((item, index) => {
      if (item.child) {
        return(
          <div key={index.toString()}>
            <ListItem className={classNames(classes.head, item.icon ? classes.iconed : '')}>
              { item.icon && (<ListItemIcon> <Ionicon icon={item.icon} /> </ListItemIcon>) }
              <ListItemText classes={{ primary: classes.primary }} variant="inset" primary={intl.formatMessage({ id: item.name })} />
              {item.badge && (<Chip color="primary" label={item.badge} className={classes.badge} />)}
            </ListItem>
            <Collapse component="div" className={classNames(classes.nolist, (item.keyParent ? classes.child : ''))}
              in={true}
              timeout="auto" unmountOnExit
            >
              <List className={classes.dense} component="nav" dense>
                { getMenus(item.child, 'key') }
              </List>
            </Collapse>
          </div>
        );
      }
      if (item.title) {
        return (
          <ListSubheader
            disableSticky
            key={index.toString()}
            component="div"
            className={classes.title}
          >
            {intl.formatMessage({ id: item.name })}
          </ListSubheader>
        );
      }
      return (
        <ListItem
          key={index.toString()}
          button
          exact
          className={classNames(classes.head, item.icon ? classes.iconed : '')}
          activeClassName={classes.active}
          component={LinkBtn}
          to={item.link}
          onClick={() => this.handleClick()}
        >
          {item.icon && (<ListItemIcon> <Ionicon icon={item.icon} /> </ListItemIcon>)}
          <ListItemText classes={{ primary: classes.primary }} primary={intl.formatMessage({ id: item.name })} />
          {item.badge && (<Chip color="primary" label={item.badge} className={classes.badge} />)}
        </ListItem>
      );
    });

    const currentDataMenu = this.handleMenu(dataMenu);

    return (
      <div>
        {getMenus(currentDataMenu)}
      </div>
    );
  }
}

MainMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.shape({}).isRequired,
  open: PropTypes.object.isRequired,
  openSubMenu: PropTypes.func.isRequired,
  toggleDrawerOpen: PropTypes.func.isRequired,
  //loadTransition: PropTypes.func.isRequired,
  dataMenu: PropTypes.array.isRequired,
  pageList: PropTypes.array,
};

MainMenu.defaultProps = {
  pageList: [],
};

const openAction = (key, keyParent) => ({ type: 'OPEN_SUBMENU', key, keyParent });
const reducer = 'ui';

const mapStateToProps = state => ({
  force: state, // force active class for sidebar menu
  open: state.getIn([reducer, 'subMenuOpen']),
  pageList: get(JSON.parse(JSON.stringify(state.get('contract'))), 'currentPageList', []),
});

const mapDispatchToProps = dispatch => ({
  openSubMenu: bindActionCreators(openAction, dispatch)
});

const MainMenuMapped = connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(MainMenu));

export default (withStyles(styles)(MainMenuMapped));
